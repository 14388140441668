import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, InputBase, List, ListItem, ListItemText, Menu, MenuItem, Typography, fade } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { divindentValue, getAvatarText, getDurationWithUnit, getEllipsisText, getNumberFormat, getSearchValue, historyPushWithSearch, numberFormater, setSearchQuery } from "../../helpers";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole, getCountryCurrency, getSingleSeasonDetails, getLeaguesSeasonsList, getLeaguesListNew } from "../../ducks/game";
import { Link, Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import MentorDashboard from "./MentorDashboard";
import PlayerDashboard from "./PlayerDashboard";
import NonPlayerDashboard from "./NonPlayerDashboard";
import { Fade } from "@material-ui/core";
import Dashboard from "./Dashboard";
import LiveScores from "../LiveScores";
import PointsTables from "../PointsTables";
import PerformanceStats from "../PerformanceStats";
import Leaderboards from "../Leaderboards";
import MatchDetails from "../LiveScores/MatchDetails";
import LiveMatches from "./LiveMatches";
import AllMatches from "./AllMatches";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareLiveScoreDialog from "../LiveScores/ShareLiveScoreDialog";
import CreatePost from "../ArenaComponents/CreatePost";
import { getTemplateTheme } from "../../ducks/common";
import { ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import DashboardNew from "./version1/DashboardNew";
import restrictImg from '../../assets/images/restrict.svg';
import SearchIcon from '@material-ui/icons/Search';
import IllustrationView from "../LiveScores/IllustrationView";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import Skeleton from "@material-ui/lab/Skeleton";
import { debounce } from "underscore";
import qs from 'query-string';
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle.svg";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as PlayerIcon } from "../../assets/icons/player.svg";
import { ReactComponent as ViewerIcon } from "../../assets/icons/viewer.svg";



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F2F5",
    width: "100%",
    maxHeight: "calc(100vh - 116px)",
    // maxHeight: 'calc(100vh - 100px)',
    overflowY: "auto",
    padding: "56px 24px 0px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      // maxHeight: 'calc(100vh - 80px)',
      // maxWidth: `calc(100vw - 150px)`,
      padding: "56px 0px 0px",
    },
  },
  gridContainer: {
    // width: '80%',
    // width: '90%',
    display: "flex",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1250,
    [theme.breakpoints.down("md")]: {
      // width: '85%',
      // width: '90%',
    },
    [theme.breakpoints.down("sm")]: {
      // width: '90%',
    },
    [theme.breakpoints.down("xs")]: {
      // width: '100%',
    },
  },
  cardStyle: {
    height: "143px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },

  cardHeadertitle: {
    color: "#A0A0A0",
    fontSize: "13px",
    lineHeight: "19.5px",
    fontWeight: "500",
  },
  avatarStyles: {
    width: 36,
    height: 36,
    fontSize: 16,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: "0.785714px solid #C4C4C4",
  },
  avatarStyle1: {
    '& .MuiCardHeader-content': {
      marginTop: '30px !important'
    },
  },
  orgCardStyle: {
    height: "430px",
    width: "430px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  playerCardStyle: {
    height: "392px",
    width: "318px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  performanceCardStyle: {
    height: "392px",
    width: "446px",
    //left: "51px",
    //top: "228px",
    marginTop: "17px",
    //borderRadius: "10px",
    border: "1px",
    padding: "10px",
    boxShadow: "0px 0px 4px 1px #EDEDED",
    borderRadius: "3px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: '120px'
  },
  paper: {
    background: "#CCCDFD",
    color: "white"
  },

  input: {
    color: "#CCCDFD",
    backgroundColor: "#CCCDFD",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#CCCDFD",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)"
    }
  },
  autocompleteRoot: {
    borderRadius: 30,
    padding: '0px 16px !important',
    fontSize: 20,
    backgroundColor: '#ebebfe',
    // width: 280
    margin: '12px 10px 0px',
    borderColor: '#EBEBFF',
    marginLeft: 32,
    color: "#5D5FEF",
  },

  tabsContainerStyles: {
    // justifyContent: 'center',
    marginLeft: 34
  },
  tabsRoot: {
    // position: 'fixed',
    // top: 60,
    // left: 0,
    // backgroundColor: '#FFF',
    width: '100%',
    maxWidth: '100%',
    minHeight: 40,
    zIndex: 11,
    transition: 'top 0.2s ease-in-out',
    boxShadow: '-4px 3px 10px -7px #000'
  },
  tabStyles: {
    minHeight: 40,
    textTransform: 'capitalize',
    color: '#262626',
    minWidth: 'auto',
    // margin: '0px 12px',
    padding: '6px 12px 0px',
    fontSize: 13,
  },
  selectedTab: {
    fontWeight: 600
  },
  button: {
    borderRadius: 30,
    color: "#5D5FEF",
    backgroundColor: "#EBEBFF",
    padding: "5px 14px",
    fontSize: "20",
    fontWeight: 500,
    marginLeft: 24,

  },
  rightIcon: {
    padding: "0px 0 0 2px",
  },

  paperRoot: {
    minWidth: 200
  },
  customWidth: {
    '& div': {
      // this is just an example, you can use vw, etc.
      width: '350px',
    }
  },
  button_: {
    color: "white",
    padding: "5px",
    textTransform: "capitalize",
    height: 40,
    width: 180,
  },
  search: {
    position: "relative",
    // backgroundColor: fade(theme.palette.common.white, 0.35),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.5),
    // },
    width: "auto",
    padding: '0px 8px',
    height: 40,
    maxWidth: 430,
    minWidth: 430,
    background: '#FFFFFF',
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    textAlign: 'center',
    alignItems: "center",
    '& .MuiInputBase-root': {
      display: 'flex'
    }
  },
  searchIcon: {
    color: "#0000004d",
    // padding: "0px 8px 0px 25px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  inputInput: {
    // border: "1px solid #0000003a",
    // borderRadius: "3px",
    // height: 22,
    // margin: "0 10px",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    fontSize: "14px",
  },
  inputRoot: {
    color: 'initial',
    height: '100%',
    width: 'inherit'
  },
  leagueFilter: {
    margin: '20px 0px',
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: theme.palette.primary.main,
      borderRadius: 6,
      color: 'white'
    }
  },
  profileTabsRoot: {
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    padding: 5,
    width: 'max-content',
    height: 'auto',
    minHeight: 'auto'
  },
  tabIndicatorProfile: {
    display: 'none'
  },
  profileTabRoot: {
    minWidth: 55,
    fontSize: 14,
    color: "#535353",
    textTransform: 'capitalize',
    minHeight: '30px',
    padding: '2px 10px'
  },
  card: {
    background: '#FFFFFF',
    border: '1px solid rgba(30, 106, 181, 0.16)',
    bordeRadius: 8,
    padding: 12,
    height: 273,
    '& .MuiCardHeader-action': {
      marginTop: '5px !important',
      marginRight: '0px !important'
    },
    '& .MuiCardHeader-title': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: 15,
    },
    '& .card_header': {
      position: 'relative',
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& .statusbtn': {
        position: 'absolute',
        top: '-25px',
        right: 0,
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        display: 'flex',
        height: 24,
        alignItems: 'center',
        textTransform: 'capitalize',
        color: 'black !important'
      },
      '& .statusbtn1': {
        position: 'absolute',
        top: '-12px',
        left: '-13px',
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: 4,
        display: 'flex',
        height: 30,
        alignItems: 'center',
        textTransform: 'capitalize',
        padding: '0px 15px',
        color: '#000000a6',
        fontSize: '14px',
        minWidth: 110,
        textOverflow: 'ellipsis',
        borderTopLeftRadius: '6px',
        "&:after": {
          content: "''",
          position: 'absolute',
          top: '0',
          right: '-9px',
          transform: 'skewX(-33deg)',
          width: '20px',
          background: '#F0F0F0',
          height: '30.2px'
        },
      }
    },
    '& .MuiIconButton-root:hover': {
      background: 'none'
    },
    '& .cardContent': {
      marginTop: '10px',
      '& .season_heading': {
        background: 'rgba(0, 0, 0, 0.06)',
        borderRadius: '2px 2px 0px 0px',
        padding: '6px 8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h5': {
          margin: 0,
          fontWeight: 400,
          fontSize: 14,
          color: 'rgba(0, 0, 0, 0.65)',
          textTransform: 'capitalize',
        },
        '& .seasnHdng': {
          margin: 0,
          fontWeight: 400,
          fontSize: 12,
          color: 'rgba(0, 0, 0, 0.65)',
          textTransform: 'uppercase',
          background: 'rgba(0, 0, 0, 0.06)',
          borderRadius: 4,
          padding: '0px 10px',
          lineHeight: '23px'
        }
      },
      '& .kpi_list': {
        minHeight: 124,
        height: 124,
        padding: '0px 5px',
        overflowY: 'auto',
        '& .kpi_lists': {
          padding: '4px 1px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& h6': {
            margin: 0,
            fontWeight: 400,
            fontSize: 15,
            color: '#2E2C2C',
            textTransform: 'capitalize',
          },
          '& h6:nth-child(2)': {
            opacity: 0.7,
          },
        },
        '&::-webkit-scrollbar': { display: 'none' }
      },
      '& .view_detail': {
        marginTop: 8,
        display: 'flex',
        justifyContent: 'space-between',
        '& .viewBtn': {
          background: '#FFFFFF',
          border: '1px solid rgba(30, 106, 181, 0.16) !important',
          borderRadius: 4,
          color: '#727272',
          padding: '2px 15px',
          fontWeight: 500,
        },
        '& .viewBtn:hover': {
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main} !important`,
          color: 'white',
          '& svg': {
            marginRight: '5px',
            fill: 'white',


          }
        },
        '& svg': {
          marginRight: '5px',
          color: '#727272'


        }


      }
    },
    '& .headerss': {
      padding: 0
    }
  },
  statusClr: {
    margin: 0,
    padding: 4,
    borderRadius: '50%',
    marginRight: 5,
    border: '2px solid',
  },
  statusClrL: {
    background: '#0A8B3E',
    borderColor: '#C2DCCC',
  },
  statusClrD: {
    background: '#F58023',
    borderColor: '#E8D1BE',
  },
  statusClrC: {
    background: '#DC1B1B',
    borderColor: '#EFC9C9',
  },
  avatarStyle: {
    width: 36,
    height: 36,
    fontSize: 16,
    borderRadius: 2,
    background: 'rgba(0, 0, 0, 0.08)',
    color: theme.palette.primary.main,
  },
  skeletonRoot: {
    "& .card": {
      bordeRadius: 8,
      padding: 12,
      borderRadius: 4,
      height: 250,
      "&:nth-child(1)": {
        borderRadius: 4
      }
    },
    '& .titleRoot': {
      height: 50
    }
  },
}));

const defaultFilters = {
  league: null,
  season: null,
  round: null,
  status: 'LIVE',
  match: null
};

const MenuOptions = [
  { id: 'DASHBOARD', name: 'Dashboard', pathname: 'dashboard' },
  { id: 'LIVE_SCORES', name: 'Live Score', pathname: 'live-scores' },
  { id: 'POINTS_TABLE', name: 'Points Table', pathname: 'points-table' },
  { id: 'ALL_MATCHES', name: 'All Matches', pathname: 'matches' },
  { id: 'STATS', name: 'Stats', pathname: 'stats' },
  { id: 'TRENDS', name: 'Trends', pathname: 'trends' },
  { id: 'LEADERBOARD', name: 'Leaderboard', pathname: 'leaderboard' },
  { id: 'REWRADS', name: 'Rewards', pathname: 'rewards' }
];

const leagueTabs = [
  { id: 'live', name: 'Live', value: 0 },
  { id: 'draft', name: 'Draft', value: 1 },
  { id: 'completed', name: 'Closed', value: 2 },
]
let pagination = { page: 0, limit: 12 };

export const intlNumberFormat = (seasonDetails, type, value) => {
  const props = {
    type: type,
    locale: seasonDetails?.country?.locale
  }
  if (seasonDetails?.numaration !== 'DEFAULT') {
    const formatter = numberFormater(props, seasonDetails?.country)
    const { divisor, label } = divindentValue(seasonDetails?.numaration);
    return (divisor && label) ? `${props?.type === "currency" ? seasonDetails?.country?.currency_symbol : ''}${formatter.format(value / divisor)}${label}` : formatter.format(value);
  }
  else {
    return new Intl.NumberFormat(seasonDetails ? seasonDetails?.country?.locale : 'en-IN', {
      style: type === "currency" ? "currency" : undefined,
      notation: 'compact',
      currency: seasonDetails ? seasonDetails?.country?.currency_code : 'INR',
      maximumFractionDigits: 2
    }).format(value);
  }
}

const Leagues = ({ history, seasonDetails, location, getLeaguesList, getLeaguesListNew, getRoundsList, getMatchesList, getOverallOrgStats, getLoggedInUserRole, getSingleSeasonDetails, getSeasonsList, getCountryCurrency, getTemplateTheme, userDetails, getLeaguesSeasonsList }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('DASHBOARD');
  const [filtersData, setFiltersData] = useState({ ...defaultFilters });
  const [leagues, setLeagues] = useState([]);
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const [seasons, setSeasons] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [leagueSearch, setLeagueSearch] = useState();
  const [leagueTab, setLeagueTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const query = qs.parse(history?.location?.search);
  const [accessLeague, setAccessLeague] = useState(false);

  const tab = getSearchValue(history, 'status');


  useEffect(() => { tab && setLeagueTab(leagueTabs.find(el => el.id === tab)?.value) }, [])
  useEffect(() => { getLeagueSeasons() }, [leagueTab])

  useEffect(() => {
    setTimeout(() => { setAccessLeague(true) }, 1000);
    // getLeagues();
    // setSearchQuery(history, { match: "" }); // disabled to work reload on allmatches screen
    setFiltersData(prevState => ({ ...prevState, match: null }));
    // historyPushWithSearch(`${path}`, history, location.search);
    getTemplateTheme();
  }, []);

  // useEffect(() => {
  //   if (filtersData.season?._id) {
  //     let seasonId = filtersData.season?._id;
  //     getLoggedInUserRole({ seasonId, onSuccessCB, onErrorCB });
  //     getSeasonDetail(seasonId);
  //   }
  // }, [filtersData.season?._id]);

  // useEffect(() => {
  //   if (filtersData.league) getSeasons(filtersData.league._id);
  // }, [filtersData.league]);


  // ------------debounce search input------------
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
  function handleDebounceFn(inputValue) {
    setLeagues([]);
    getLeagueSeasons(inputValue);
    setLeagueSearch(inputValue);
  }

  const getLeagueSeasons = (searchVal) => {
    setLoading(true);
    getLeaguesSeasonsList({
      params: { limit: pagination.limit, page: 1, status: tab ? tab : leagueTabs[leagueTab]?.id, name: searchVal ? searchVal : leagueSearch },
      onSuccessCB: onSuccessLeagues, onErrorCB: onErrorLeagues,
    })
  }
  const onSuccessLeagues = (result, headers) => {
    setLoading(true);
    if (result) {
      pagination.page = parseInt(headers.current_page);
      pagination.total_count = parseInt(headers.total_count);
      setLeagues(prevState => ([...prevState, ...result]));
      // const searchValue = getSearchValue(history, 'league');
      // !searchValue && setSearchQuery(history, { league: result[0]?._id });
      // setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj?._id === searchValue) : result[0], round: null }));
      setLoading(true);
    }
    setLoading(false);
  }
  const onErrorLeagues = (error) => {
    setLoading(false);
    setFiltersData(prevState => ({ ...defaultFilters }));
  }


  const getLeagues = () => {
    setLoading(true);
    getLeaguesListNew({ //getLeaguesList
      params: { limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        setLeagues([...result]);
        const searchValue = getSearchValue(history, 'league');
        !searchValue && setSearchQuery(history, { league: result[0]?._id });
        setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
        setLoading(false);

      }, onErrorCB: (error) => {
        setLoading(false);
        setFiltersData(prevState => ({ ...defaultFilters }));

      }
    })
  };

  const getSeasons = (leagueId) => {
    // setLoading(true);
    setLoadingSeasons(true);
    getSeasonsList({
      leagueId: leagueId,
      params: { limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        if (result.length > 0) {
          setSeasons([...result]);
          const searchValue = getSearchValue(history, 'season');
          !searchValue && setSearchQuery(history, { season: result[0]?._id });
          if (!(filtersData.season?._id)) { setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null })); }
          setSelectedSeason(searchValue ? result.find(obj => obj._id === searchValue) : result[0]);
        }
        else {
          // setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
          setSeasons([]);
          setRounds([]);
        }
        setLoadingSeasons(false);
      }, onErrorCB: (error) => {
        setLoadingSeasons(false);
        setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));

      }
    })
  }

  const getSeasonDetail = (seasonId) => {
    getSingleSeasonDetails({
      seasonId: seasonId,
      onSuccessCB: (result) => {
        if (result) {
          setSeasons([...result]);
          const searchValue = getSearchValue(history, 'season');
          !searchValue && setSearchQuery(history, { season: result?._id });
          setFiltersData(prevState => ({ ...prevState, season: result, round: null }));
          setSelectedSeason(result);
        }
      }, onErrorCB: (error) => { }
    })
  }

  const getCountries = () => {
    getCountryCurrency({
      onSuccessCountry: (result,) => { }, onErrorCountry: (error) => { }
    })
  }

  const getRounds = (seasonId) => {
    setLoading(true);
    getRoundsList({
      seasonId: seasonId,
      params: { limit: 5000, page: 1 },
      onSuccessCB: (result, headers) => {
        if (result.length > 0) {
          setRounds([...result]);
          if (location.pathname.split('/')[2] === 'dashboard') {
            setFiltersData(prevState => ({ ...prevState, match: null, round: null }));
            const searchValue = getSearchValue(history, 'round');
            !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
          }
          else {
            const searchValue = getSearchValue(history, 'round');
            !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
            setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id)) }));
          }
        }
        else {
          setRounds([]);
        }
        setLoading(false);
      }, onErrorCB: (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
          variant: 'error',
          preventDuplicate: true
        });
      }
    })
  }

  const onSuccessCB = (response) => {
    if (response) {
      setUserInfo({ ...response });
    }
  }

  const onErrorCB = (error) => {
  }


  // const handleChangeTab = (event, newValue) => {
  //   if (MenuOptions[newValue])
  //     setValue(MenuOptions[newValue].id);
  // };

  const handleTabChange = (e, newValue) => {
    historyPushWithSearch(`${path}/${MenuOptions[newValue].pathname}`, history, location.search);
  }

  const handleRightClick = (event) => {
    event.preventDefault();
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleSeasonClick = (season) => {
    getSeasonDetail(season?._id);
    setAnchorEl(null);
    setMenuPosition(null);
    setSelectedSeason(season);
    let setObj = { season };
    if (season?.season_format === 'HEAD_TO_HEAD') getRounds(season?._id);
    else setObj['round'] = null;
    setFiltersData(prevState => ({ ...prevState, ...setObj }));
    setSearchQuery(history, { season: season?._id });
  }

  const [loadingSeasons, setLoadingSeasons] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleItemClick = (event, league) => {
    setAnchorEl(event.currentTarget);
    setFiltersData(prevState => ({ ...prevState, league }));
    setSearchQuery(history, { league: league?._id });
    // if (league) getSeasons(league._id);
  };

  const handleKeySearch = (searchedVal) => {
    setLeagueSearch(searchedVal);
    debounceFn(searchedVal);
  };

  const fetchMoreData = e => {
    getLeaguesSeasonsList({
      params: { limit: pagination.limit, page: pagination.page + 1, status: leagueTabs[leagueTab].id, name: leagueSearch && leagueSearch },
      onSuccessCB: onSuccessLeagues, onErrorCB: onErrorLeagues,
    });
  }
  const handleChangeTab = (event, newValue) => {
    setLeagueTab(newValue);
    setSearchQuery(history, { status: leagueTabs[newValue].id });
    if (leagueTab !== newValue) setLeagues([]);
  };

  const skeletonLoading = (
    <Grid container spacing={1} className={classes.skeletonRoot}>
      {[1, 2, 3, 4, 5, 6].map((ld, id) => (
        <Grid key={id} item lg={4} md={6} sm={6} xs={12}>
          <Card className="card">
            <CardHeader style={{ padding: 0 }}
              avatar={<Skeleton variant="reactangle" width={38} height={38} style={{ borderRadius: 4 }} />}
              action={<Skeleton variant="reactangle" width={60} height={25} style={{ marginTop: 14, marginRight: 8, borderRadius: 4 }} />}
              title={<Skeleton height={20} width="95%" />}
            />
            <CardContent style={{ padding: 0, marginTop: '10px', }}>
              <div className="titleRoot">
                <Skeleton variant="rect" className={classes.skeletonAction} />
                <Skeleton variant="rect" className={classes.skeletonAction} />
              </div>
              <div style={{ padding: '0px 5px' }}>
                <Skeleton height={21} style={{ margin: '0px 0px 6px 0px' }} />
                <Skeleton height={21} style={{ marginBottom: 6 }} />
                <Skeleton height={21} style={{ marginBottom: 6 }} />
                <Skeleton height={21} />
              </div>
              <Skeleton height={28} variant="rect" style={{ margin: '10px 5px 10px 0px', float: 'right', borderRadius: 4, width: '110px' }} />
            </CardContent>
          </Card>
        </Grid>
      ))
      }
    </Grid >
  );

  const onClickDetails = (league, season) => {
    // getSeasonDetail(seasonId);
    // setTimeout(() => {
    // console.log('aaaaaaa', leagues.find(obj => obj.league_id === league))
    // setFiltersData(prevState => ({ ...prevState, league: leagues.find(obj => obj.league_id === league) }));
    let qryparams = qs.parse(history.location.search);
    delete qryparams['status'];
    // localStorage.setItem('league', JSON.stringify(leagues.find(obj => obj.league_id === league)))
    history.push({ pathname: `/leaguess/c2`, search: qs.stringify({ season }) });
    // }, 100);
  }

  const onClickSeasonRules = (season) => {
    history.push({ pathname: `leaguess/c2/season-rules/performanceHealth`, search: qs.stringify({ season }) });

  }


  return (
    <>
      {/* { */}
      {/* leagues?.find(d => d._id === query?.league) ? */}
      <div id="leagues-root" style={{ display: "flex", flexDirection: 'column', backgroundColor: "#F1F0FD", width: '100%', height: "calc(100vh - 60px)", overflowY: 'auto' }}>
        {/* <div style={{ width: '100%' }}>
          <div style={{ marginLeft: 26, margin: 10 }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              onClick={handleRightClick}
              className={classes.button}
            //onMouseOver={handleRightClick}
            >
              {selectedSeason ? selectedSeason.name : filtersData.league?.name}
              <KeyboardArrowDownOutlined className={classes.rightIcon} />

            </Button>



            <Menu
              id="simple-menu"
              open={!!menuPosition}
              onClose={() => setMenuPosition(null)}
            // className={classes.customWidth}
            anchorOrigin={{ vertical: 220, horizontal: 100 }}
              transformOrigin={{ vertical: 220, horizontal: 100 }}
              anchorPosition="right"
              PaperProps={{
                style: {
                  // top:'110px !important',
                  // left:'35px !important'
                },
              }}
            >
              {leagues.length > 0 && leagues.map((league) => (
                <MenuItem onClick={(e) => handleItemClick(e, league)} style={{ justifyContent: "space-between" }}>{league.name}
                  <ChevronRightIcon className={classes.rightIcon} />
                </MenuItem>
              ))
              }
            </Menu>
            {<Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "center", horizontal: "left" }}
              anchorPosition="right"
              style={{ minWidth: 200 }}
              classes={{ paper: classes.paperRoot }}
            >
              {loadingSeasons ? <p style={{ margin: 16, textAlign: 'center' }}>Loading....</p> : [
                (seasons.length > 0) ? seasons.map((season) => (
                  <MenuItem onClick={() => handleSeasonClick(season)}>{season.name}</MenuItem>
              ))
              }
            </Menu>
            {<Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              TransitionComponent={Fade}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "center", horizontal: "left" }}
              anchorPosition="right"
              style={{ minWidth: 200 }}
              classes={{ paper: classes.paperRoot }}
            >
              {loadingSeasons ? <p style={{ margin: 16, textAlign: 'center' }}>Loading....</p> : [
                (seasons.length > 0) ? seasons.map((season) => (
                  <MenuItem onClick={() => handleSeasonClick(season)}>{season.name}</MenuItem>
                ))
                  : <p style={{ margin: 16, textAlign: 'center' }}>No Seasons Found</p>
              ]}
            </Menu>}
          </div>
        </div>
        </div> */}
        {/* <Tabs
          id="filter-tabs"
          value={MenuOptions.map((option) => option.pathname).indexOf(location.pathname.split('/')[2])}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          // scrollButtons="on"
          aria-label="scrollable auto tabs example"
          className={classes.tabsRoot}
          classes={{ flexContainer: classes.tabsContainerStyles }}
        >
          {
            [...MenuOptions].map((obj, i) =>
              <Tab className={classes.tabStyles} classes={{ selected: classes.selectedTab }} key={i} label={obj.name} />
            )
          }
        </Tabs> */}

        <div style={{ margin: '20px 25px 15px 25px' }}>
          <div className={classes.searchDiv}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search league name"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={leagueSearch}
                onChange={(e) => { handleKeySearch(e.target.value) }}
              />
            </div>
          </div>
          <div className={classes.leagueFilter}>
            <Tabs
              value={leagueTab}
              onChange={handleChangeTab}
              textColor="primary"
              aria-label="full width tabs example"
              className={classes.profileTabsRoot}
              classes={{ indicator: classes.tabIndicatorProfile }}
            >
              <Tab classes={{ root: classes.profileTabRoot }} label="Live" />
              <Tab classes={{ root: classes.profileTabRoot }} label="Draft" />
              <Tab classes={{ root: classes.profileTabRoot }} label="Closed" />
            </Tabs>
          </div>
          {loading && skeletonLoading}
          {leagues && leagues.length > 0 ? (
            <InfiniteScroll
              dataLength={leagues.length}
              next={fetchMoreData}
              hasMore={leagues.length !== pagination.total_count}
              scrollableTarget="leagues-root"
              loader={skeletonLoading}
              style={{ overflow: "unset" }}
            >
              <Grid container spacing={1}>
                {leagues.length > 0 && leagues.map((league, index) => (
                  league &&
                  <Grid key={index} item lg={4} md={6} sm={6} xs={12}>
                    <Card className={classNames(classes.card, classes.avatarStyle1)} variant="outlined">
                      <CardHeader className="headerss card_header"
                        avatar={
                          <>
                            {league?.roles && league?.roles === 'viewer' ?
                              <button className="statusbtn1">
                                <Typography style={{ paddingRight: 6., marginTop: 6 }} variant="h6" component="h6" title='League Viewer'>
                                  <ViewerIcon fontSize="small" />
                                </Typography> Viewer
                              </button>
                              :
                              league?.roles === 'Non_player' ?
                                <button className="statusbtn1">
                                  <Typography style={{ paddingRight: 6., marginTop: 6 }} variant="h6" component="h6" title='Non Player'>
                                    <PlayerIcon fontSize="small" />
                                  </Typography> Non Player
                                </button>
                                :
                                <button className="statusbtn1">
                                  <Typography style={{ paddingRight: 6., marginTop: 6 }} variant="h6" component="h6" title='Player'>
                                    <PlayerIcon fontSize="small" />
                                  </Typography> Player
                                </button>
                            }
                            <Avatar style={league?.roles && league?.roles === 'viewer' ? { marginTop: 30 } : { marginTop: 30 }} className={classes.avatarStyle} alt={league?.league_short_code} src={league?.logo_url && league?.logo_url} >
                              {league?.league_name && getAvatarText(league?.league_name)}
                            </Avatar>
                          </>
                        }
                        action={<IconButton aria-label="settings" style={{ alignItems: 'flex-start' }}>
                          {/* {league?.roles && league?.roles === 'viewer' &&
                            <Typography style={{ paddingRight: 6 }} variant="h6" component="h6" title='League Viewer'>
                              <VisibilityOutlined fontSize="small" />
                            </Typography>
                          } */}
                          <button className="statusbtn"> <p className={classNames(classes.statusClr, league?.status === 'live' ? classes.statusClrL : league?.status === 'draft' ? classes.statusClrD : classes.statusClrC)}></p> {leagueTabs?.find(d => d?.id === league?.status).name}</button>
                        </IconButton>}
                        title={league?.league_name && getEllipsisText(league?.league_name, 25)} />
                      <CardContent className="headerss cardContent">
                        <div className="season_heading">
                          <h5>{league?.roles && league?.roles === 'viewer' ? 'Organization Performance' : 'My Performance'}</h5>
                          <Typography className="seasnHdng" variant="h6" component="h6" title={league?.name}>
                            {league?.name && getEllipsisText(league?.name, 12)}
                          </Typography>
                        </div>
                        <div className="kpi_list">
                          {league?.performances.length > 0 && (league?.performances).length > 0 && (league?.performances).map((kpi, index) => (
                            <div className="kpi_lists">
                              <h6>{getEllipsisText(kpi?.kpi_name, 35)}</h6>
                              <h6>
                                {kpi?.unit_of_measurement === 'CURRENCY' ?
                                  intlNumberFormat(league, "currency", kpi?.actual)
                                  // <GetCountryCurrencyFormat locale={league?.country?.locale} country={league?.country?.currency_code} type="currency" value={kpi?.actual} />
                                  : kpi?.unit_of_measurement === 'NUMBER' ?
                                    intlNumberFormat(league, "number", kpi?.actual)
                                    // <GetCountryCurrencyFormat locale={league?.country?.locale} country={league?.country?.currency_code} type="number" value={kpi?.actual} />
                                    : `${getNumberFormat(userDetails?.countryCode, kpi?.actual, null)} ${kpi?.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi?.unit_of_measurement === 'DURATION' ? getDurationWithUnit(kpi?.actual, kpi?.unit) : ''}`
                                }
                              </h6>
                            </div>
                          ))
                          }
                        </div>
                        <Divider />
                        <div className="view_detail">
                          <Button onClick={() => { onClickSeasonRules(league?._id) }} className="viewBtn" variant="outlined" color="primary"> <InfoOutlinedIcon className="infoIcon" fontSize="small" /> Season Rules</Button>

                          <Button onClick={() => { onClickDetails(league?.league_id, league?._id) }} className="viewBtn" variant="outlined" color="primary">View Details</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
                }
              </Grid>
            </InfiniteScroll>
          ) :
            < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
          }
        </div>

        {/* <Switch> */}
        {/* <Route path={`${path}/performance`}> */}
        {/* <Dashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} /> */}
        {/* <DashboardNew filtersData={filtersData} rounds={rounds} userInfo={userInfo} setFiltersData={setFiltersData} />
          </Route> */}
        {/* <Route path={`${path}/live-scores`}>
            <LiveScores filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/points-table`} >
            <PointsTables filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/matches`} >
            <AllMatches filtersData={filtersData} setFiltersData={setFiltersData} rounds={rounds} userInfo={userInfo} getRounds={getRounds} />
          </Route>
          <Route path={`${path}/stats`} >
            <PerformanceStats type='stats' filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/trends`} >
            <PerformanceStats type='trends' filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route>
          <Route path={`${path}/leaderboard`} >
            <Leaderboards filtersData={filtersData} setFiltersData={setFiltersData} />
          </Route> */}
        {/* <Redirect to={{ pathname: `${path}`, search: location.search }} /> */}
        {/* </Switch> */}
        {/* <div style={{ padding: '24px 46px' }}>
          {
            userInfo?.role === 'PLAYER' ?
              <PlayerDashboard filtersData={filtersData} setFiltersData={setFiltersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
              : userInfo?.role === 'MENTOR' ? <MentorDashboard filtersData={filtersData} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} navigateToTeamLeaderboard={navigateToTeamLeaderboard} />
                : <NonPlayerDashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} natigateToAllStats={natigateToAllStats} natigateToLiveScores={natigateToLiveScores} navigateToTeamLeaderboard={navigateToTeamLeaderboard} navigateToPlayerLeaderboard={navigateToPlayerLeaderboard} />
          }
        </div> */}
        <ShareLiveScoreDialog />
        <CreatePost />

      </div >
      {/* :
          <div style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', backgroundColor: "#F1F0FD", width: '100%', padding: '5% 0px' }}>
            {accessLeague ?
              <div style={{ textAlign: 'center' }}>
                <img src={restrictImg} width="160px" height="160px" alt="restrictImg" />
                <h1 style={{ textAlign: 'center', margin: '12px 0px', opacity: '0.8' }}>We are sorry</h1>
                <p style={{ textAlign: 'center', margin: 0 }}>The page you are trying to access has restricted access</p>
                <p style={{ textAlign: 'center', margin: 0, marginBottom: 20 }}>Please refer to your system administator</p>
                <Link style={{ textDecoration: 'none' }} to={`/wall`}><Button variant="contained" color="primary" className={classes.button_}>Go Back</Button></Link>
              </div>
              : ''}
          </div> */}
      {/* } */}
    </>

  );
};

const mapStateToProps = (state) => ({
  userDetails: state.user,
  seasonDetails: state.game.seasonDetails
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: value => dispatch(setLoading(value)),
    getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
    getSeasonsList: (options) => dispatch(getSeasonsList(options)),
    getSingleSeasonDetails: (options) => dispatch(getSingleSeasonDetails(options)),
    getLeaguesList: (options) => dispatch(getLeaguesList(options)),
    getLeaguesListNew: (options) => dispatch(getLeaguesListNew(options)),
    getLeaguesSeasonsList: (options) => dispatch(getLeaguesSeasonsList(options)),
    getRoundsList: (options) => dispatch(getRoundsList(options)),
    getMatchesList: (options) => dispatch(getMatchesList(options)),
    getTemplateTheme: (options) => dispatch(getTemplateTheme(options)),
    getCountryCurrency: (options) => dispatch(getCountryCurrency(options)),

  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Leagues));