import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardContent, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { useRouteMatch } from "react-router-dom";
import FieldSet from "../GlobalComponents/FieldSet";
import { formatDate, formatDateUTC } from "../../helpers";
import Axios from "axios";
import { INCENTIVES_DETAILS, INCENTIVES_EARNINGS, INCENTIVES_LIST, INCENTIVES_PERFORMANCE } from "../../config";
import { useSnackbar } from "notistack";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '20px 35px',
        [theme.breakpoints.down('xs')]: {
            padding: '20px 20px',
        },
    },
    fieldSetRoot: {
        margin: '20px 0 20px',
        boxShadow: '0px 0px 4px 0px rgba(161, 161, 161, 0.25)',
    },
    fieldSetStyles: {
        border: '1px solid #e7e7e7ee',
        padding: 25,
    },
    fieldSetChildRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        flexWrap: 'wrap',
        borderRadius: 3,
    },
    fieldSetChildRoot1: {
        display: 'flex'
    },
    fieldLabel: {
        color: '#58595b',
        fontSize: 16,
        margin: 0
    },
    fieldValue: {
        color: '#58595b',
        fontSize: 16,
        fontWeight: 600,
        marginTop: 0,
        marginBottom: 5
    },
    incentivePlans: {
        margin: '20px 0 0',
        padding: 15,
        boxShadow: '0px 0px 4px 0px rgba(161, 161, 161, 0.25)',
    },
    incplan: {
        minHeight: 356,
        maxHeight: 356,
        overflow: 'auto',
        boxShadow: '0px 0px 4px 0px rgba(161, 161, 161, 0.25)',
        padding: 12,
        '& .plans': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 20px 0px 10px',
            border: '1px solid #efefef',
            borderRadius: 5,
            margin: '8px 0px',
            cursor: 'pointer',
        },
        '& .selectedPlan': {
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 20px 0px 10px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 5,
            margin: '8px 0px',
            cursor: 'pointer',
        }
    },
    planDetails: {
        padding: 12,
        maxHeight: 356,
        overflow: 'auto',
    },
    card: {
        height: 100,
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        padding: '0px 5px',
        '& .cardContent': {
            borderRadius: 4,
            padding: '4px 8px',
            '& h1': {
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 14,
                margin: '6px 0',
                fontWeight: 500,
            }
        },
        '& .perform': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& span': {
                fontSize: 11,
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& p': {
                margin: 0,
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.9)',
            },
            '& .values': {
                textAlign: 'center'
            },
            '& .divider': {
                margin: '0px 2px'
            }
        }
    },
    card1: {
        background: '#FFFFFF',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        padding: '0px 5px',
        '& .cardContent': {
            borderRadius: 4,
            padding: '4px 8px',
        },
        '& .values': {
            textAlign: 'center',
            '& span': {
                fontSize: 14,
            },
            '& p': {
                fontSize: 13,
                margin: 0,
                padding: '6px 0px'
            }
        },
    },
}));

const IncentivesList = [
    {
        name: 'Total Earning',
        amount: 423000,
        startDate: 'Feb 23',
        endDate: 'Feb 24',
    },
    {
        name: 'Total Quarter Earning',
        amount: 112000,
        startDate: 'Jan 23',
        endDate: 'Mar 24',
    },
    {
        name: 'Curent Month Earning',
        amount: 34520,
        startDate: 'February',
        endDate: null,
    }
]

const kpiList = [
    {
        name: 'Disapatch Value',
        target: '50 Cr',
        actual: '28 Cr',
        ach: '56 %'
    },
    {
        name: 'STBL Disapatch Value',
        target: '3 Cr',
        actual: '82 L',
        ach: '27.3 %'
    },
    {
        name: 'Unsecured Disapatch Value',
        target: '5 Cr',
        actual: '2.5 Cr',
        ach: '50 %'
    },
    {
        name: 'Secured Disapatch Value',
        target: '30 Cr',
        actual: '10 Cr',
        ach: '33.3 %'
    },
]


const Incentives = ({ history, userInfo, location }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedPlan, setSelectedPlan] = useState();
    const [plaDetails, setPlaDetails] = useState({});
    const [earnings, setEarnings] = useState([]);
    const [performance, setPerformance] = useState();




    useEffect(() => {
        getIncentivesPlans();
        incentiveEarnings();
    }, []);

    useEffect(() => {
        if (selectedPlan) {
            getIncentivesPlanDetails(selectedPlan);
            incentivesPerformance(selectedPlan);
        }
    }, [selectedPlan]);

    // -------------incentives----------
    const getIncentivesPlans = () => {
        setLoading(true);
        Axios.get(INCENTIVES_LIST, { params: { limit: 1000, page: 1 } }).then(response => {
            setData(response.data);
            setSelectedPlan(response.data[0])
        }).catch(error => {
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Data.', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    // ---------------incentive Details------------
    const getIncentivesPlanDetails = (selectedPlan) => {
        setLoading(true);
        Axios.get(INCENTIVES_DETAILS(selectedPlan?._id)).then(response => {
            setPlaDetails(response.data);
        }).catch(error => {
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Data.', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    // -------------incentive perofrmances----------
    const incentivesPerformance = (selectedPlan) => {
        setLoading(true);
        Axios.get(INCENTIVES_PERFORMANCE(selectedPlan?._id), { params: { limit: 1000, page: 1 } }).then(response => {
            setPerformance(response.data);
        }).catch(error => {
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Data.', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    // -------------incentive earnings----------
    const incentiveEarnings = () => {
        setLoading(true);
        Axios.get(INCENTIVES_EARNINGS, { params: { limit: 1000, page: 1 } }).then(response => {
            setEarnings(response.data)
        }).catch(error => {
            enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Data.', {
                variant: 'error',
                preventDuplicate: true
            });
        })
    }

    const handlePlanClick = (plan) => {
        setSelectedPlan(plan);
    }


    return (
        <div id="buzz-data" className={classes.root}>
            <div className='' >
                <div>
                    <Typography variant="h6">My Incentives</Typography>
                </div>
                <div className={classes.fieldSetRoot}>
                    <FieldSet rootStyles={classes.fieldSetStyles} >
                        <div className={classes.fieldSetChildRoot}>
                            <div className={classes.fieldSetChildRoot1}>
                                <div>
                                    <p className={classes.fieldLabel}>Total Earning </p>
                                    <p className={classes.fieldValue}>₹ {(earnings[0]?.total?.value)}</p>
                                </div>
                            </div>
                            <Divider style={{ height: 50, width: 2 }} orientation="vertical" />
                            <div className={classes.fieldSetChildRoot1}>
                                <div>
                                    <p className={classes.fieldLabel}>Quarterly Earning
                                        <span style={{ background: '#e2e2e2', borderRadius: 3, padding: '3px 8px', fontSize: 12, marginLeft: 5 }}>
                                            {formatDateUTC(earnings[1]?.quarter?.start_date)} {earnings[1]?.quarter?.end_date && <span>{`- ${formatDateUTC(earnings[1]?.quarter?.end_date)}`}</span>}
                                        </span>
                                    </p>
                                    <p className={classes.fieldValue}>₹ {(earnings[1]?.quarter?.value)}</p>
                                </div>
                            </div>
                            <Divider style={{ height: 50, width: 2 }} orientation="vertical" />
                            <div className={classes.fieldSetChildRoot1}>
                                <div>
                                    <p className={classes.fieldLabel}>Monthly Earning
                                        <span style={{ background: '#e2e2e2', borderRadius: 3, padding: '3px 8px', fontSize: 12, marginLeft: 5 }}>
                                            {formatDateUTC(earnings[2]?.month?.start_date)} {earnings[2]?.month?.end_date && <span>{`- ${formatDateUTC(earnings[2]?.month?.end_date)}`}</span>}
                                        </span>
                                    </p>
                                    <p className={classes.fieldValue}>₹ {(earnings[2]?.month?.value)}</p>
                                </div>
                            </div>
                        </div>
                    </FieldSet>
                </div>
                <div>
                    <Typography variant="p">Incentives Plans</Typography>
                </div>
                <div className={classes.incentivePlans}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <div className={classes.incplan}>
                                {data.length ? data.map((plan, index) => (
                                    <div key={index} onClick={() => { handlePlanClick(plan) }} className={(plan?._id === selectedPlan?._id) ? "selectedPlan" : "plans"}>
                                        <p style={{ margin: 0, padding: '6px 0px' }}>{plan?.name}</p>
                                        <ArrowRightIcon />
                                    </div>
                                ))
                                    : <p>No Plans Available...</p>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <div className={classes.planDetails}>
                                <div style={{ marginBottom: 10 }}>
                                    <Typography variant="p">{plaDetails?.name}</Typography>
                                </div>
                                <div style={{ background: '#0000000a', padding: '15px 14px', height: '100%' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <Typography variant="p">Incentive Program Details</Typography>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid item md={2} sm={6} xs={12}>
                                            <Card className={classes.card1} variant="outlined">
                                                <CardContent className="cardContent">
                                                    <div className='values'>
                                                        <span style={{ color: '#9a9a9a' }}>Incentive Earning</span>
                                                        <p>₹ {plaDetails?.total_earnings}</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <Card className={classes.card1} variant="outlined">
                                                <CardContent className="cardContent">
                                                    <div className='values'>
                                                        <span style={{ color: '#9a9a9a' }}>Plan Starts - Ends</span>
                                                        <p>{formatDate(plaDetails?.plan_start_date)} <span> - {formatDate(plaDetails?.plan_end_date)}</span></p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item md={2} sm={6} xs={12}>
                                            <Card className={classes.card1} variant="outlined">
                                                <CardContent className="cardContent">
                                                    <div className='values'>
                                                        <span style={{ color: '#9a9a9a' }}>Plan Period</span>
                                                        <p>{plaDetails?.plan_period}</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={12}>
                                            <Card className={classes.card1} variant="outlined">
                                                <CardContent className="cardContent">
                                                    <div className='values'>
                                                        <span style={{ color: '#9a9a9a' }}>Payout Starts - Ends</span>
                                                        <p>{formatDate(plaDetails?.payout_start_date)} <span> - {formatDate(plaDetails?.payout_start_date)}</span></p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item md={2} sm={6} xs={12}>
                                            <Card className={classes.card1} variant="outlined">
                                                <CardContent className="cardContent">
                                                    <div className='values'>
                                                        <span style={{ color: '#9a9a9a' }}>Payout Cycle</span>
                                                        <p>{plaDetails?.payout_period}</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <div style={{ margin: '20px 0px 10px' }}>
                                        <Typography variant="p">KPI-Linked Incentive Progress</Typography>
                                    </div>
                                    <Grid container spacing={2}>
                                        {
                                            performance?.length ?
                                                performance?.map((item, index) =>
                                                    <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
                                                        <Card key={index} className={classes.card} variant="outlined">
                                                            <CardContent className="cardContent">
                                                                <h1>{item?.kpi_name}</h1>
                                                                <div className="perform">
                                                                    <div className="values">
                                                                        <span>Target</span>
                                                                        <p>{item?.target}</p>
                                                                    </div>
                                                                    <Divider orientation="vertical" className="divider" flexItem />
                                                                    <div className="values">
                                                                        <span>Actual</span>
                                                                        <p>{item?.actual}</p>
                                                                    </div>
                                                                    <Divider orientation="vertical" className="divider" flexItem />
                                                                    <div className="values">
                                                                        <span>Ach</span>
                                                                        <p>{item?.achievement}</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )
                                                :
                                                <div style={{ textAlign: 'center', padding: 20, color: 'dimgray' }}> No kpi Data Available ....</div>
                                        }
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Incentives));