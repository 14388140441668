import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { TextField, makeStyles } from '@material-ui/core';
import PlayerLeaderboard from './PlayerLeaderboard';
import TeamLeaderboard from './TeamLeaderborad'
import { connect } from 'react-redux';
import { getSeasonPlayersRoles } from '../../ducks/game';
import { Autocomplete } from '@material-ui/lab';
import { DecimalViewSwitch } from '../LiveScores/CustomSwitch';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        width: '100%',
    },
    contentRoot: {
        padding: '0px 40px 20px',
    },
    tabsRoot: {
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
        }
    },
    flexContainerStyles: {
        marginLeft: 34
    },
    filterSec: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 40px 10px',
    },
    autocompleteRoot: {
        width: "160px",
        height: "40px",
        marginRight: "10px",
        borderRadius: 5,
        overflow: "clip",
        whiteSpace: "nowrap",
        background: 'white',
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
}))


const LeaderboardIndex = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [leaderboardFilter, setLeaderboardFilter] = useState({
        roles: null,
        teams: null,
        regions: null,
        divisions: null
    });
    const [decimalView, setDecimalView] = useState(false);

    useEffect(() => {
        const dubLeaderboardFilter = localStorage.getItem('leaderBoardFilters') || '';
        if (dubLeaderboardFilter) {
            const copyParams = JSON.parse(dubLeaderboardFilter);
            if (copyParams?.from === 'VIEWALL_LABLES') {
                setLeaderboardFilter({
                    roles: copyParams?.roles ? props.seasonRoles.find(el => el._id === copyParams.roles) : null,
                    teams: copyParams?.teams ? props.seasonTeams.find(el => el._id === copyParams.teams) : null,
                    regions: copyParams?.regions ? props.seasonRegions.find(el => el._id === copyParams.regions) : null,
                    divisions: copyParams?.divisions ? props.seasonDivisions.find(el => el._id === copyParams.divisions) : null
                });
            }
            else {
                setLeaderboardFilter(JSON.parse(dubLeaderboardFilter));
            }
        }
    }, []);

    //filters on changes
    const handleChange = (data, field) => {
        setLeaderboardFilter({ ...leaderboardFilter, [field]: data });
        // setSearchQuery(history, { [field]: data?._id });
        let finalParams = { roles: leaderboardFilter.roles, regions: leaderboardFilter.regions, teams: leaderboardFilter.teams, divisions: leaderboardFilter.divisions };
        delete finalParams[field];
        // customQueryFormation(history, { [field]: data?._id, ...finalParams });
        let leaderBoardFilters = { [field]: data, ...finalParams }
        localStorage.setItem('leaderBoardFilters', JSON.stringify(leaderBoardFilters));
    };



    return (
        <div className={classes.root} id="leaderboard_data" style={{ height: '100%', overflow: 'auto' }}>
            <div>
                <div className={classes.filterSec}>
                    <div style={{ margin: '8px 0px', display: 'flex' }}>
                        <>
                            {((props.filtersData.season?.season_format === 'PLAYER_RACE') || (props.filtersData.season?.season_format === 'TEAM_RACE' && props.filtersData.season?.auto_player_sprints)) &&
                                <Autocomplete
                                    id="roles"
                                    clearable
                                    options={props.seasonRoles}
                                    getOptionLabel={(option) => option.name}
                                    value={leaderboardFilter?.roles}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label={'Roles'} variant="outlined" size="small" />}
                                    onChange={(...args) => handleChange(args[1], 'roles')}
                                />
                            }
                            {((props.filtersData.season?.season_format === 'PLAYER_RACE') || (props.filtersData.season?.season_format === 'TEAM_RACE' && props.filtersData.season?.auto_player_sprints)) &&
                                <Autocomplete
                                    id="regions"
                                    clearable
                                    options={props.seasonRegions}
                                    getOptionLabel={(option) => option.name}
                                    value={leaderboardFilter?.regions}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label={'Regions'} variant="outlined" size="small" />}
                                    onChange={(...args) => handleChange(args[1], 'regions')}
                                />
                            }
                            {((props.filtersData.season?.season_format === 'TEAM_RACE' && props.filtersData.season?.auto_player_sprints) || (props.filtersData.season?.season_format === 'TEAM_RACE')) &&
                                <Autocomplete
                                    id="teams"
                                    clearable
                                    options={props.seasonTeams}
                                    getOptionLabel={(option) => option.name}
                                    value={leaderboardFilter?.teams}
                                    classes={{ inputRoot: classes.autocompleteRoot }}
                                    renderInput={(params) => <TextField {...params} label={'Teams'} variant="outlined" size="small" />}
                                    onChange={(...args) => handleChange(args[1], 'teams')}
                                />}
                            <Autocomplete
                                id="divisions"
                                clearable
                                options={props.seasonDivisions}
                                getOptionLabel={(option) => option.name}
                                value={leaderboardFilter?.divisions}
                                classes={{ inputRoot: classes.autocompleteRoot }}
                                renderInput={(params) => <TextField {...params} label={'Divisions'} variant="outlined" size="small" />}
                                onChange={(...args) => handleChange(args[1], 'divisions')}
                            />
                        </>
                    </div>
                    <div>
                        <p className="table-title decimal-switch-root" >
                            <p></p>
                            <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                        </p>
                    </div>
                </div>
                <div id="leaderboards-data" className={classes.contentRoot} >
                    <Switch>
                        <Route path={`${path}/team`}>
                            <TeamLeaderboard filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} seasonRegions={props.seasonRegions} seasonTeams={props?.seasonTeams} seasonRoles={props?.seasonRoles} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                        </Route>
                        <Route path={`${path}/player`}>
                            <PlayerLeaderboard filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} seasonRegions={props.seasonRegions} seasonTeams={props?.seasonTeams} seasonRoles={props?.seasonRoles} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                        </Route>
                        {
                            ((props.filtersData.season?.season_format === 'PLAYER_RACE') || (props.filtersData.season?.season_format === 'TEAM_RACE' && props.filtersData.season?.auto_player_sprints)) ?
                                <Redirect to={{ pathname: `${path}/player`, search: props.location.search }} />
                                :
                                props.filtersData.season?.season_format === 'TEAM_RACE' ?
                                    <Redirect to={{ pathname: `${path}/team`, search: props.location.search }} />
                                    :
                                    <Redirect to={{ pathname: `${path}/player`, search: props.location.search }} />
                        }
                    </Switch>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    seasonDetails: state.game.seasonDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaderboardIndex));
