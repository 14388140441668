import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { TextField, colors, makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { getEllipsisText } from "../../helpers";
import ReactEditIcon from "../../assets/images/editIcon.png";


const useStyles = makeStyles((theme) => ({
    root: {

    },
    close: {
        '& button': {
            background: 'none',
            color: 'black',
            padding: 0
        }
    },
    recentList: {
        overflow: 'auto',
        transition: 'width 0.5s ease',
        backgroundColor: '#f9f9f9',
        padding: '0px',
        height: 'calc(100vh - 60px)',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-track ': {
            background: "#f1f1f1"
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#cccccc'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#a3a3a3'
        }
    },
    newChat: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0px',
        cursor: 'pointer',
        '& p': {
            margin: 0,
            '& img': {
                width: 24,
                height: 24,
                opacity: 0.8
            }
        }
    }
}));


const RecentMessages = ({ history, chatLists, OnCreateChat, setRecentChatId, setNewChat, recentChatId }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const toggleSidebar = () => setIsOpen(!isOpen);


    useEffect(() => {
        if (!recentChatId) {
            setRecentChatId(chatLists[0]?._id);
        }
    }, [chatLists])

    const updateMessage = () => {

    }

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.recentList} style={{ width: isOpen ? '250px' : '0' }}>
                    {isOpen &&
                        <nav style={{ padding: 10 }}>
                            <div className={classes.newChat} onClick={() => { setRecentChatId(null); setNewChat(true); }}>
                                <p style={{ cursor: 'pointer' }}>Ryzeup Chat </p>
                                <p> <img src={ReactEditIcon} alt="Edit" /></p>
                            </div>
                            <div>
                                {chatLists.map((chat, i) =>
                                (
                                    <div key={i}>
                                        {/* {
                                            recentChatId?.id !== chat?.id ?
                                                <>
                                                    <form onSubmit={updateMessage} >
                                                        <TextField autoComplete="off" id="display_name" style={{ border: 'none', background: ' none' }} type="text" value={chat.name} onChange={updateMessage} />
                                                    </form>
                                                </>
                                                :
                                                <> */}
                                        <p onClick={() => { setRecentChatId(chat?._id); history.push(`/chatbot/${chat?._id}`) }} className={recentChatId === chat?._id ? 'recentChatsSelected' : 'recentChats'} >{getEllipsisText(chat?.title, 40)}</p>
                                        {/* </>
                                        } */}
                                    </div>
                                )
                                )}
                            </div>
                        </nav>}
                </div>
                <div className={classes.close}>
                    <button onClick={toggleSidebar}>
                        {isOpen ? '<' : '>'}
                    </button>
                </div>
            </div>
        </div >
    );
};


const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
    };
};
export default withRouter(connect(null, mapDispatchToProps)(RecentMessages));