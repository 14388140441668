import { Card, CardActionArea, CardContent, ClickAwayListener, Dialog, FormControl, FormControlLabel, Grid, IconButton, Switch, TextField, Typography, useTheme } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Close, InsertEmoticon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { setLoading } from '../../ducks/loading';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getUserNotification, getUserNotificationMessages, getUserNotificationMessageCategories } from '../../ducks/game';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: '#f5f6f7',
        color: '#535353'
    },
    dialogPaper: {
        borderRadius: 15,
        margin: 0,
        minWidth: '70vw',
        '& .MuiCardContent-root': {
            padding: '6px',
            minHeight: '80px'
        },
        '& .MuiOutlinedInput-root': {
            padding: '7px',
        },
        '& .MuiFormControl-root label': {
            fontSize: '14px',
            top: '-6px',
        }

    },
    spacingField: {
        paddingTop: '6px !important',
    },
    spacingFieldForCategory: {
        paddingTop: '6px !important',
        '& .MuiOutlinedInput-root': {
            padding: '7px',
            width: '200px',
            height: '48px',
        }
    },
    dialogTitle: {
        backgroundColor: "#f5f6f7",
        fontSize: 16,
        fontWeight: 600,
        color: '#262626'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 6
    },
    dialogContent: {
        paddingTop: '8px',

    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 7px !important',
    },
    footerButton: {
        margin: '0 18px',
        width: 135,
        height: 47,
        '&:last-child': {
            marginRight: 0
        }
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
    height: {
        minHeight: "40px !important",
        maxHeight: "100% !important",
        borderRadius: "4px"
    },

    tagRoot: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            cursor: 'pointer',
            margin: 0,
            paddingBottom: 3,
            fontSize: 16,
            width: 'fit-content',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& span': {
            fontSize: 12
        }
    },
    //emoji picker styles
    emojiPicker: {
        position: "absolute",
        bottom: 50,
        right: 0,
        padding: "0 15px",
        zIndex: 99999999,
        "& .emoji-mart": {
            minWidth: '50% !important',
            float: 'right',
            [theme.breakpoints.down('sm')]: {
                width: "auto !important",
            },
        },
        "& .emoji-mart-scroll": {
            height: 105
        }
    },
    emojiPicker1: {
        position: "absolute",
        bottom: 63,
        right: 0,
        padding: "0 15px",
        zIndex: 99999999,
        "& .emoji-mart": {
            minWidth: '50% !important',
            float: 'right',
            [theme.breakpoints.down('sm')]: {
                width: "auto !important",
            },
        },
        "& .emoji-mart-scroll": {
            height: 170
        }
    },
    emojiButtn: {
        position: 'absolute',
        right: 0,
        bottom: '0px',
        paddingRight: '0px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0px'
        },
    },
    emojiButtn1: {
        position: 'absolute',
        right: 0,
        bottom: '10px',
        paddingRight: '0px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0px'
        },
    },
    // message card css
    msgCard: {
        width: '100%',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '80px',
    },
    msgCardActive: {
        width: '100%',
        height: '80px',
        border: '1.5px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: `${theme.palette.primary.light}3A`
    },
    msgTitle: {
        // maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '13px',
        fontWeight: 600,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'

    },
    msgBody: {
        // maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '10px',
        fontWeight: 600,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    notificationHeading: {
        padding: 0,
        margin: '3px 0px 3px 0px',
    },
    messagesContainer: {
        backgroundColor: '#f1f1f1de',
        padding: '12px 5px 5px 5px',
        width: '100%',
        borderRadius: '10px',
    },
    emptyMessagesView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        maxHeight: "185px",
        height: "185px",
    }

}));

let emojiPicker = null;
let emojiPicker1 = null;
const sendTypes = [
    { name: 'Push Notification', id: 2 },
    // { name: 'Chat Message', id: 6 },
];
const NotificationDialog = ({ selectedEmps, setOpenConfirmDialog, handleClose, open, getUserNotification, getUserNotificationMessages, setSelectedEmps, handleUncheck, getUserNotificationMessageCategories, from, ...other }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [employeeList, setEmployeeList] = useState([]);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showEmojis1, setShowEmojis1] = useState(false);
    const theme = useTheme();
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [sendThrough, setSendThrough] = useState(sendTypes[0]);
    const [details, setDetails] = useState({
        title: '',
        message: '',
        hasError: false
    });
    const [grpChecked, setGrpChecked] = useState({ group: true, newMessage: false });
    const [messageList, setMessageList] = useState([]);
    const [messageStatus, setMessageStatus] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);


    useEffect(() => {
        if (categoryList.length > 0) {
            let categoryFrom = categoryList.find((category) => category.name.toLowerCase() === from)
            if (categoryFrom) {
                setSelectedCategory(categoryFrom);
            }
        }

    }, [categoryList])
    useEffect(() => {

        GetMessageList()


    }, [selectedCategory]);
    useEffect(() => { GetCategoriesList() }, [from]);

    useEffect(() => {
        setEmployeeList(selectedEmps);
        setSelectedMembers(selectedEmps)
    }, [])

    const GetCategoriesList = () => {
        setLoading(true);
        getUserNotificationMessageCategories({ params: { limit: 5000, page: 0 }, onSuccessUserNotificationMessageCategories, onErrorUserNotificationMessageCategories })

    }

    const onSuccessUserNotificationMessageCategories = (res) => {
        if (res) {
            setLoading(true);
            setCategoryList(res.filter(obj => obj.active === true));

        }

    }

    const onErrorUserNotificationMessageCategories = (err) => {
        if (err) {
            enqueueSnackbar('Failed to fetch Notification Categories', {
                variant: 'error',
                preventDuplicate: true
            });
        }

    }

    const GetMessageList = () => {
        setLoading(true);
        getUserNotificationMessages({
            params: {
                category_id: selectedCategory ? selectedCategory._id : null,
            }, onSuccessUserNotificationMessages, onErrorUserNotificationMessages
        })
    }

    const onSuccessUserNotificationMessages = (res) => {
        if (res) {
            setLoading(true);
            setMessageList(res);
            if (res.length > 0) {
                setMessageStatus(res[0])
            }
            else {
                setMessageStatus([]);

            }
        }

    }

    const onErrorUserNotificationMessages = (err) => {
        console.log('notificationsreponseeeeerror', err)
        setLoading(false);
        if (err) {
            enqueueSnackbar('Failed to fetch Notification Messages', {
                variant: 'error',
                preventDuplicate: true
            });
        }

    }

    const autocompleteHandlerMembers = (e, values) => {
        const uniqueChaseons = [...new Map(values.map(item => [item.player?._id, item])).values()];
        setSelectedMembers(uniqueChaseons);
    };
    const hadleChange = (e, key) => {
        setDetails({ ...details, [key]: e.target.value })
    }
    const handleSubmit = () => {
        if (((grpChecked.newMessage == false) && (messageStatus?.length == 0)) || selectedMembers.length === 0) {
            if (messageStatus?.length === 0) {
                enqueueSnackbar(`please enter message to send`, {
                    variant: "error",
                    preventDuplicate: true
                });
            }
            else {
                enqueueSnackbar(`please enter required fields`, {
                    variant: "error",
                    preventDuplicate: true
                });
            }

        }
        else if ((grpChecked.newMessage == true) && !(details?.title && details?.message)) {
            enqueueSnackbar(`please enter required fields`, {
                variant: "error",
                preventDuplicate: true
            });
        }
        else {
            enqueueSnackbar('Sending in Progress.', {
                variant: 'info',
                preventDuplicate: true,
            });
            handleClose(false)
            const membersDub = selectedMembers
            const batchSize = 200;
            for (let i = 0; i < membersDub.length; i += batchSize) {
                const members_array = membersDub.slice(i, i + batchSize);

                const members_list = members_array.map((d) => {
                    const obj = {
                        userId: d.employee_id,
                        title: (grpChecked?.group === false) ? (grpChecked?.newMessage === true) ? 'Hey ' + d.player?.name + ' ' + details?.title : 'Hey ' + d.player?.name + ' ' + messageStatus?.title : (grpChecked?.newMessage === true) ? details?.title : messageStatus?.title,
                    }
                    return obj;
                })
                let obj = { mode: sendThrough.id, members: members_list, message: (grpChecked?.newMessage === true) ? details.message : messageStatus.message, group: grpChecked.group };
                let objs = { mode: sendThrough.id, members: members_array.map(d => d.employee_id), title: (grpChecked?.newMessage === true) ? details?.title : messageStatus?.title, message: (grpChecked?.newMessage === true) ? details.message : messageStatus.message, group: grpChecked.group };
                getUserNotification({
                    requestObj: grpChecked?.group === false ? obj : objs,
                    onSuccessUserNotificationPost, onErrorUserNotificationPost
                })
            }
        }
    }

    const onSuccessUserNotificationPost = (res) => {
        enqueueSnackbar('Notification Sent Successfully.', {
            variant: 'success',
            preventDuplicate: true,
        });
        handleClose(false);
        setSelectedEmps([]);
        handleUncheck('', '', 'close');
    }

    const onErrorUserNotificationPost = () => {
        enqueueSnackbar('Notification Sent Error', {
            variant: 'error',
            preventDuplicate: true,
        });

    }

    const hadleChangeSwitch = (event, field) => {
        setGrpChecked({ ...grpChecked, [field]: event.target.checked });
        if (field == 'message') {
            setDetails({ ...details, title: '', message: '' });
        }

    };
    const messageCardClick = (e, msg) => {
        setMessageStatus(msg);
    }

    // ---emoji sec adding --
    const handleShowEmojis = e => {
        setShowEmojis(true);
    };
    const handleShowEmojis1 = e => {
        setShowEmojis1(true);
    };
    const addEmoji = (e, key) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach(el => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        if (key === 'title') {
            setDetails({ ...details, [key]: details.title + emoji })
        } else {
            setDetails({ ...details, [key]: details.message + emoji })
        }
    };
    const handleClickAway = e => {
        setShowEmojis(false);
    };
    const handleClickAway1 = e => {
        setShowEmojis1(false);
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="confirmation-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth={true}
            onClose={() => handleClose(false)}
            {...other}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                Notification Message
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    disableClearable
                                    multiple
                                    limitTags={5}
                                    id="employee"
                                    autoComplete
                                    openOnFocus
                                    noOptionsText="No Members"
                                    value={selectedMembers}
                                    options={employeeList}
                                    onChange={autocompleteHandlerMembers}
                                    getOptionLabel={option => option.player?.name}
                                    style={{ backgroundColor: '#FFF' }}
                                    renderInput={params => <TextField {...params} variant="outlined" placeholder="Members" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} className={classes.spacingFieldForCategory} >
                            <Autocomplete
                                disableClearable
                                id='send through'
                                autoComplete
                                options={sendTypes}
                                getOptionLabel={(option) => option.name}
                                value={sendThrough}
                                classes={classes.autocompleteRoot}
                                renderInput={(params) => <TextField {...params} label="Send Through" variant="outlined" />}
                                onChange={(e, val) => setSendThrough(val)}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.spacingFieldForCategory}>
                            <Autocomplete
                                clearable
                                id='Category'
                                autoComplete
                                options={categoryList.filter(obj => obj.active === true)}
                                getOptionLabel={(option) => option.name}
                                value={selectedCategory}
                                renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                                onChange={(e, val) => setSelectedCategory(val)}
                                classes={classes.autocompleteRoot}


                            />
                            {/* <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    clearable
                                    limitTags={2}
                                    id="category"
                                    autoComplete
                                    openOnFocus
                                    noOptionsText="No Categories"
                                    value={selectedCategory}
                                    options={categoryList.filter(obj => obj.active === true)}
                                    onChange={(e, val) => setSelectedCategory(val)}
                                    getOptionLabel={option => option.name}
                                    style={{ backgroundColor: '#FFF' }}
                                    renderInput={params => <TextField {...params} variant="outlined" placeholder="Categories" />}
                                />
                            </FormControl> */}
                        </Grid>
                        <Grid container xs={6} className={classes.spacingField}>
                            <Grid item xs={1}></Grid>
                            <Grid item >
                                <div className={classes.autocompleteRoot}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={grpChecked.group} color="primary" value="start" labelPlacement="start" onChange={e => hadleChangeSwitch(e, 'group')} name="group" />
                                        }
                                        label="Broadcast"
                                    />
                                </div>
                            </Grid>
                            <Grid item  >
                                <div className={classes.autocompleteRoot}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={grpChecked.newMessage} value="start" labelPlacement="start" color="primary" onChange={e => hadleChangeSwitch(e, 'newMessage')} name="newMessage" />
                                        }
                                        label="New Message"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {
                            grpChecked?.newMessage == true ?
                                <>
                                    <Grid style={{ position: 'relative' }} item xs={12} className={classes.spacingField}>
                                        <div className={classes.tagRoot}>
                                            <TextField
                                                fullWidth
                                                required
                                                variant="outlined"
                                                id="title"
                                                value={details.title}
                                                label="Title"
                                                autoComplete='off'
                                                placeholder=" Title"
                                                name="title"
                                                inputProps={{ maxLength: 50 }}
                                                rowsMax={1}
                                                onChange={e => hadleChange(e, 'title')}
                                            />
                                            <p className={classes.emojiButtn}>
                                                <IconButton
                                                    onClick={() => handleShowEmojis()}
                                                    className={classes.iconButtonEmoji}
                                                    aria-label="menu"
                                                >
                                                    <InsertEmoticon />
                                                </IconButton>
                                            </p>
                                            {showEmojis && (
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <span
                                                        className={classes.emojiPicker}
                                                        ref={el => (emojiPicker = el)}
                                                    >
                                                        <Picker
                                                            onSelect={e => addEmoji(e, 'title')}
                                                            emojiTooltip={true}
                                                            title=""
                                                            showPreview={false}
                                                            showSkinTones={false}
                                                            color={theme.palette.primary.main}
                                                            exclude={['flags']}
                                                        />
                                                    </span>
                                                </ClickAwayListener>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid style={{ position: 'relative' }} item xs={12} className={classes.spacingField}>
                                        <div className={classes.tagRoot}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                id="notificationMsg"
                                                label="Message"
                                                autoComplete='off'
                                                placeholder=" Message ..."
                                                fullWidth
                                                multiline
                                                name="notificationMsg"
                                                inputProps={{ maxLength: 150 }}
                                                rowsMax={2}
                                                value={details.message}
                                                onChange={e => hadleChange(e, 'message')}
                                            />
                                            <p className={classes.emojiButtn1}>
                                                <IconButton
                                                    onClick={() => handleShowEmojis1()}
                                                    className={classes.iconButtonEmoji}
                                                    aria-label="menu"
                                                >
                                                    <InsertEmoticon />
                                                </IconButton>
                                            </p>
                                            {showEmojis1 && (
                                                <ClickAwayListener onClickAway={handleClickAway1}>
                                                    <span
                                                        className={classes.emojiPicker1}
                                                        ref={el => (emojiPicker1 = el)}
                                                    >
                                                        <Picker
                                                            onSelect={e => addEmoji(e, 'message')}
                                                            emojiTooltip={true}
                                                            title=""
                                                            showPreview={false}
                                                            showSkinTones={false}
                                                            color={theme.palette.primary.main}
                                                            exclude={['flags']}
                                                        />
                                                    </span>
                                                </ClickAwayListener>
                                            )}
                                        </div>
                                    </Grid>
                                </>
                                :
                                <div style={{ padding: 0, width: '100%', marginRight: '13px' }}>
                                    <h4 className={classes.notificationHeading}>Select Notification Message</h4>
                                    <div className={classes.messagesContainer} >

                                        <div style={{ maxHeight: '185px', overflowY: 'auto', height: "185px" }} >
                                            {messageList.length > 0 ?
                                                <Grid container xs={12} spacing={2}>
                                                    {messageList.map((msg) => {
                                                        return (
                                                            <Grid item xs={4}>
                                                                <Card key={msg.index} className={(messageStatus?._id === msg._id) ? classes.msgCardActive : classes.msgCard} onClick={e => { messageCardClick(e, msg) }}>
                                                                    <CardActionArea>
                                                                        <CardContent>
                                                                            <Typography className={classes.msgTitle} gutterBottom variant="h6" component="div">
                                                                                {msg.title}
                                                                            </Typography>
                                                                            <Typography className={classes.msgBody} variant="body2" color="text.secondary">
                                                                                {msg.message}
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </CardActionArea>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    })
                                                    }
                                                </Grid>
                                                :
                                                <div className={classes.emptyMessagesView}>
                                                    <h4>No Message To Display</h4>

                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </Grid>
                </>
                <DialogActions style={{ padding: '20px 0px 0px' }}>
                    <Button onClick={() => handleClose(false)} className={classes.footerButton} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button style={{ marginLeft: 30 }} onClick={() => handleSubmit()} className={classes.footerButton} variant="contained" color="primary">
                        Send
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (value) => dispatch(setLoading(value)),
        getUserNotification: options => dispatch(getUserNotification(options)),
        getUserNotificationMessages: options => dispatch(getUserNotificationMessages(options)),
        getUserNotificationMessageCategories: options => dispatch(getUserNotificationMessageCategories(options))


    };
};


export default withRouter(connect(null, mapDispatchToProps)(NotificationDialog));
