import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import PlayerLeaderboard from './PlayerLeaderboard';
import TeamLeaderboard from './TeamLeaderborad'
import { historyPushWithSearch } from '../../helpers';
import { CustomListGroup } from '../LiveScores/RoundsListView';
import CreatePost from '../ArenaComponents/CreatePost';
import { connect } from 'react-redux';
import { getSeasonPlayersRoles } from '../../ducks/game';
import LeaderboardIndex from './LeaderboardIndex';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f2f5',
        // backgroundColor: '#FFF',
        width: '100%',
    },
    contentRoot: {
        padding: '0px 40px 20px',
        // padding: '80px 80px 20px',
        // overflowY: 'auto',
        // height: 'calc(100vh - 160px)',
        // width: 'calc(100% - 160px)'
    },
    tabsRoot: {
        // position: 'fixed',
        top: 60,
        left: 0,
        backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 45,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        borderBottom: '1px solid #f0f2f5',
    },
    tabRoot: {
        minHeight: 45,
        textTransform: 'capitalize',
        color: '#262626 !important',
        minWidth: 'auto',
        margin: '0px 12px',
        padding: '6px 2px',
        [theme.breakpoints.up("sm")]: {
            // minWidth: 125
        }
    },
    flexContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    scrollButtons: {
        // width: 25
    }
}))

const MenuOptions = [
    { label: 'Core', value: 'core', pathname: 'core' },
    { label: 'Bonus', value: 'bonus', pathname: 'bonus' },
    // { label: 'Team', value: 'team', pathname: 'team' },
    // { label: 'Player', value: 'player', pathname: 'player' },
];

export const leaderBoardFilterBy = [
    { name: 'Core Kpi', id: 'core', value: 0, path: 'core' },
    { name: 'Bonus KPI', id: 'bonus', value: 1, path: 'bonus' },
    { name: 'Team', id: 'team', value: 2, path: 'team' },
    { name: 'Role', id: 'role', value: 3, path: 'role' },
    { name: 'Region', id: 'region', value: 4, path: 'region' },
    { name: 'By Division', id: 'division', value: 5, path: 'division' },
]

const Leaderboards = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const [step, setStep] = useState('')
    const [seasonRoles, setSeasonRoles] = useState([]);

    const handleTabChange = (newValue) => {
        if (newValue.value !== step) {
            setStep(newValue.value)
            historyPushWithSearch(`${path}/${newValue.pathname}`, props.history, props.location.search);
            localStorage.setItem('leaderBoardFilters', '');
        }
    }

    useEffect(() => {
        if (props.filtersData?.season?._id) {
            getRolesList(props.filtersData?.season?._id);
        }
    }, [props.filtersData?.season?._id]);


    //season roles list
    const getRolesList = (seasonId) => {
        props.getSeasonPlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    ele._id = ele?.role_id;
                    return ele;
                })
                setSeasonRoles(result);
            }, onErrorCB: (error) => {

            }
        });
    }

    return (
        <div className={classes.root} id="leaderboard_data" style={{ height: '100%', overflow: 'auto' }}>
            <div>
                {/* <Tabs
                    id="season-matches-tabs"
                    value={MenuOptions.map((option) => { return option.pathname }).indexOf(props.location.pathname.split('/')[3])}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabsRoot}
                    classes={{ flexContainer: classes.flexContainerStyles, scrollButtons: classes.scrollButtons }}
                >
                    {
                        [...MenuOptions].map((obj, i) =>
                            <Tab className={classes.tabRoot} key={i} label={obj.label} />
                        )}
                </Tabs> */}
                {/* {((props.filtersData.season?.season_format === 'TEAM_RACE' && props.filtersData.season?.auto_player_sprints) || (props.filtersData.season?.season_format === 'HEAD_TO_HEAD')) && */}
                <CustomListGroup data={MenuOptions} selectedItem={MenuOptions.find((option) => option.pathname === props.location.pathname.split('/')[4])} onClick={handleTabChange} />
                {/* } */}
                <div id="leaderboards-data" className={classes.contentRoot} >
                    <Switch>
                        {/* <Route path={`${path}/team`}>
                            <TeamLeaderboard filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} />
                        </Route>
                        <Route path={`${path}/player`}>
                            <PlayerLeaderboard filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} seasonRegions={props.seasonRegions} seasonRoles={seasonRoles} />
                        </Route> */}
                        <Route path={`${path}/core`}>
                            <LeaderboardIndex filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} seasonRegions={props.seasonRegions} seasonTeams={props?.seasonTeams} seasonRoles={seasonRoles} />
                        </Route>
                        <Route path={`${path}/bonus`}>
                            <LeaderboardIndex filtersData={props.filtersData} setFiltersData={props.setFiltersData} seasonDivisions={props?.seasonDivisions} seasonRegions={props.seasonRegions} seasonTeams={props?.seasonTeams} seasonRoles={seasonRoles} />
                        </Route>
                        <Redirect to={{ pathname: `${path}/core`, search: props.location.search }} />
                        {/* {
                            props.filtersData.season?.season_format === 'TEAM_RACE' ?
                                <Redirect to={{ pathname: `${path}/team`, search: props.location.search }} />
                                :
                                <Redirect to={{ pathname: `${path}/player`, search: props.location.search }} />
                        } */}
                    </Switch>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    seasonDetails: state.game.seasonDetails
})

const mapDispatchToProps = (dispatch) => {
    return {
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Leaderboards));
