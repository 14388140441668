import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import './ChatBot.css';
import RecentMessages from "./RecentMessages";
import ChatList from "./ChatList";
import { useSnackbar } from "notistack";
import { getChatsList } from "../../ducks/chatgpt";
import { useRouteMatch } from 'react-router-dom';


export const typeOfChatRoles = [{ name: 'Search', id: 'SEARCH' }, { name: 'Agent', id: 'AGENT' }]

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
    }
}));


const ChatBot = ({ getChatsList }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [chatLists, setChatLists] = useState([]);
    const [recentChatId, setRecentChatId] = useState(null);
    const [newChat, setNewChat] = useState(null);
    const [typeOfRole, setTypeOfRole] = useState(typeOfChatRoles[0]);
    const { params } = useRouteMatch();

    useEffect(() => {
        if (params?.chatId) {
            setRecentChatId(params?.chatId)
        }
    }, [params?.chatId]);


    useEffect(() => {
        getRecentChatsList();
    }, [newChat])



    // -----------chat list-------
    const getRecentChatsList = () => {
        setLoading(true);
        getChatsList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setChatLists([...result]);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
            }
        })
    }
    // -----------chat list-------



    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', width: '100%' }}>
                <RecentMessages chatLists={chatLists} setRecentChatId={setRecentChatId} setTypeOfRole={setTypeOfRole} typeOfRole={typeOfRole} setNewChat={setNewChat} recentChatId={recentChatId} />
                <ChatList recentChatId={recentChatId} setTypeOfRole={setTypeOfRole} typeOfRole={typeOfRole} newChat={newChat} setNewChat={setNewChat} setRecentChatId={setRecentChatId} />
            </div>
        </div >
    );
};


const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getChatsList: (options) => dispatch(getChatsList(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatBot));
