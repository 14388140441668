import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { formatDate, getBase64WithUrl, getCurrencyFormat, getNumberFormat } from '../../helpers';
import moment from 'moment';
import LeaguesIcon from "../../assets/images/leagues-logo-1.png";
import TeamLogo from "../../assets/images/team_logo.png"
import { DecimalViewSwitch } from '../LiveScores/CustomSwitch';
import ProfileLogo from '../AvatarComponent/ProfileLogo';
import { getUploadLogsInfo } from '../../ducks/game';
import GetCountryCurrencyFormat from './GetCountryCurrencyFormat';


const useStyles = makeStyles((theme) => ({
    imageTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& div:first-child': {
            marginRight: 10,
            textAlign: 'right',
            '& p': {
                margin: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },
    image1Title: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        margin: '-60px 0px',
        '& div:first-child': {
            textAlign: 'right',
            '& p': {
                margin: 0,
                padding: 0,
                '&:last-child': {
                    fontSize: 11
                }
            }
        }
    },
    logo: {
        width: '120px',
        height: '120px',
        marginRight: '10px',
        borderRadius: '60px',
        border: '1px solid black'

    },
    tableTitle: {
        fontSize: 30,
        // margin: '80px 0 0px',
        '& p': {
            margin: 0,
            '&:last-child': {
                fontSize: 20,
                '& .divider': {
                    margin: '0 10px',
                    color: 'orange',
                    fontSize: 40,
                    fontWeight: 600,
                    verticalAlign: 'middle'
                }
            }
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    table: {
        width: '100%',
        padding: '20px'


    },
    leaguesLogo: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 85,
        backgroundColor: '#fff',
        color: '#9262e2',
        padding: 5,
        fontSize: 12,
        borderRadius: 5,
        height: 13,

    },
    leagues: {
        display: 'flex',
        flexDirection: 'row'
    },
    kpiShortcode: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: '40px',
        marginRight: '20px'


    },
    kpisContainer: {
        display: 'flex',
        justifyContent: 'center',
        '& hr': {
            '&:last-child': {
                display: 'none'
            }
        }
    },
    kpisContainer2: {
        display: 'flex',
        justifyContent: 'center',
        '& hr': {
            margin: '0px 50px 0px 50px',
            '&:last-child': {
                display: 'none'
            }
        }
    },
    teamProfile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffff',
        fontFamily: 'Poppins',
        padding: '10px'
    }

}));

const PerformanceTableTemplate = ({ render = false, id = "", filtersData, data, columns, components, orgDetails, divisionName = '', belongsTo, theme, userDetails, dynamicColumns, decimalView, getUploadLogsInfo, from, isUploadLogRequired, reporteeName }) => {
    const classes = useStyles();
    const [leagueLogo, setLeagueLogo] = useState("");
    const [orgLogo, setOrgLogo] = useState("");
    const [playerLogo, setPlayerLogo] = useState("")
    const [teamLogo, setTeamLogo] = useState("")
    const [tableData, setTableData] = useState([])
    const [uploadLogDate, setUploadLogDate] = useState(null)
    const TeamPerformance = ['RANK', 'TEAM', 'POINTS']
    const PlayerPerformance = ['RANK', 'PLAYER', 'POINTS']




    useEffect(() => {
        if (isUploadLogRequired && filtersData?.season?.season_format !== 'HEAD_TO_HEAD') {
            if (belongsTo === "Teams") {
                getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: "", match_id: filtersData.match?._id, type: "TEAM_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })
            }
            else {
                if (belongsTo === "Players" && filtersData?.season?.season_format === 'TEAM_RACE') {
                    getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: "", match_id: filtersData.playermatch?._id, type: "PLAYER_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

                }
                else {
                    getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: "", match_id: filtersData.match?._id, type: "PLAYER_SPRINT" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

                }

            }
        }
        else if (isUploadLogRequired) {
            if (belongsTo === "Teams") {
                getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: filtersData?.round?._id, match_id: filtersData.match?._id, type: "TEAM_ROUND" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

            }
            else {
                getUploadLogsInfo({ seasonId: filtersData?.season?._id, params: { round_id: filtersData?.round?._id, match_id: filtersData.match?._id, type: "PLAYER_ROUND" }, onSuccessUploadLogsInfo, onErrorUploadLogsInfo })

            }
        }


    }, [filtersData?.season?._id, filtersData?.round?._id, filtersData?.match?._id, filtersData?.playermatch?._id])

    const onSuccessUploadLogsInfo = (response) => {
        if (response) {
            setUploadLogDate(response)
        }

    }

    const onErrorUploadLogsInfo = (error) => {
        // console.log('UploadLogs Error', error)
        setUploadLogDate(null)

    }

    useEffect(() => {
        if (orgDetails?.logo_url) {
            getBase64WithUrl(orgDetails?.logo_url).then((res) => {
                setOrgLogo(res);
            }).catch((err) => {
                // console.log('org error', err.response));
            })
        }
    }, [orgDetails?.logo_url]);

    useEffect(() => {
        if (filtersData?.league?.logo_url) {
            getBase64WithUrl(filtersData?.league?.logo_url).then((res) => {
                setLeagueLogo(res);
            }).catch((err) => console.log('league error', err.response));
        }
    }, [filtersData?.league?.logo_url]);

    useEffect(() => {
        if (belongsTo === 'Teams' && (data?.length > 0)) {
            const copyOfData = JSON.parse(JSON.stringify(data));
            const sortedData = copyOfData.sort((p1, p2) => (p1.position > p2.position) ? 1 : (p1.position < p2.position) ? -1 : 0)
            data.forEach((obj, i) => {
                getBase64WithUrl(obj.team?.logo_url).then((res) => {
                    sortedData[i].base64URL = res;
                }).catch((err) => console.log('league error', err.response));
            });
            setTableData(sortedData);
        }
        else
            if (belongsTo === 'Players' && (data?.length > 0)) {
                const copyOfData = JSON.parse(JSON.stringify(data));
                const sortedData = copyOfData.sort((p1, p2) => (p1.position > p2.position) ? 1 : (p1.position < p2.position) ? -1 : 0)
                data.forEach((obj, i) => {
                    getBase64WithUrl(obj.player?.logo_url).then((res) => {
                        sortedData[i].base64URL = res;
                    }).catch((err) => console.log('league error', err.response));
                });
                setTableData(sortedData);
            }
    }, [data]);

    const { background: { isGradiant, top, bottom, overAll }, heading, table_head_row, table_data_row } = theme;
    return (
        <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0 }}>

            {/* to hide position: 'absolute', zIndex: -1 */}
            {/* height: render ? null : 0 */}
            <div id={id} className="liveScore-table" style={{ height: render ? null : 0, background: isGradiant ? `linear-gradient(0deg, ${bottom}, ${top})` : overAll, fontFamily: 'Poppins' }}>
                {filtersData?.league?.logo_url ?
                    < div style={{ display: 'flex', alignItems: 'center', fontSize: '5px', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                        <div><img src={leagueLogo} alt="logo" width="150" height="150" /></div>
                        <div><img src={orgLogo} alt="logo" width="150" height="150" /></div>

                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '5px', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                        <div><img src={TeamLogo} alt="logo" width="150" height="150" /></div>
                        <div><img src={orgLogo} alt="logo" width="150" height="150" /></div>

                    </div>
                }
                {
                    render &&
                    <>
                        <div>
                            <div>
                                <div className={classes.tableTitle} >
                                    {belongsTo === 'Teams' ?
                                        <p style={{ color: heading?.color, fontSize: heading?.size, marginTop: '-15px' }}>{(filtersData.season?.divisions_enabled && divisionName) ? <span>TEAM {divisionName} - </span> : reporteeName && <span> {reporteeName} - </span>}TEAM  SCORECARD</p> :
                                        <p style={{ color: heading?.color, fontSize: heading?.size, marginTop: '-15px' }}>{(filtersData.season?.divisions_enabled && divisionName) ? <span>PLAYER {divisionName} - </span> : reporteeName && <span> {reporteeName} - </span>} PLAYER  SCORECARD</p>
                                    }
                                    {
                                        belongsTo === 'Teams' ?
                                            <p style={{
                                                fontSize: '28px', paddingBottom: 10, fontFamily: 'Poppins', color: '#ffff',
                                            }}>TOP 3 TEAMS {uploadLogDate && <span> - TILL {formatDate(uploadLogDate?.created_at)}</span>}</p>
                                            : <p style={{ fontSize: '28px', paddingBottom: 10, fontFamily: 'Poppins', color: '#ffff', }}>TOP 3 PLAYERS {uploadLogDate && <span> - TILL {formatDate(uploadLogDate?.created_at)}</span>}</p>

                                    }
                                    {
                                        from !== 'performance' &&
                                        <div className={classes.detailsContainer}>
                                            <p style={{ color: '#262348', fontSize: '35px' }}>{filtersData.season?.season_format === 'TEAM_RACE' ? 'Team Race' : filtersData.season?.season_format === 'PLAYER_RACE' ? 'Player Race' : 'Head To Head'} -<span >{filtersData.round && filtersData.season?.season_format === 'HEAD_TO_HEAD' ? `ROUND  ${filtersData.round?.no}` : belongsTo === 'Players' && filtersData.season?.season_format === 'TEAM_RACE' ? filtersData.playermatch?.title : filtersData.match?.title}</span></p>
                                            <p style={{ color: '#262348', fontSize: '25px' }}>

                                                {filtersData.round && filtersData.season?.season_format === 'HEAD_TO_HEAD' ?
                                                    <span>{formatDate(filtersData.round?.start_date)} - {formatDate(filtersData.round?.end_date)}</span> :
                                                    belongsTo === 'Players' && filtersData.season?.season_format === 'TEAM_RACE' ?
                                                        <span>{formatDate(filtersData.playermatch?.start_date)} - {formatDate(filtersData.playermatch?.end_date)}</span> :

                                                        <span>{formatDate(filtersData.match?.start_date)} - {formatDate(filtersData.match?.end_date)}</span>
                                                }
                                            </p>
                                        </div >
                                    }

                                </div >
                                <table className={classes.table} >
                                    {
                                        components?.Header ?
                                            [components.Header({ columns })]
                                            :
                                            <thead>
                                                {belongsTo === 'Teams' ?
                                                    <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size, fontFamily: 'Poppins' }}>{TeamPerformance.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr> :
                                                    <tr style={{ color: table_head_row?.color, backgroundColor: table_head_row?.background_color, fontSize: table_head_row?.size, fontFamily: 'Poppins' }}>{PlayerPerformance.map((col, i) => (!col.hidden && <th key={i}>{col}</th>))}</tr>

                                                }
                                            </thead>
                                    }
                                    <tbody>
                                        {
                                            tableData.slice(0, 3).map((row, rowIndex) =>
                                                <tr key={rowIndex} style={{ color: table_data_row?.color || '#000', backgroundColor: table_data_row?.background_color, fontSize: table_data_row?.size, fontFamily: 'Poppins' }}>
                                                    <td>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <span style={{ marginRight: '10px' }}>{row?.position}</span>
                                                            {/* {(dynamicColumns.length) < 5 ?
                                                                row.base64URL ? <img src={row.base64URL} alt="teamLogo" className={classes.logo} /> : belongsTo === 'Players' ? <ProfileLogo name={row.player?.name} /> : <ProfileLogo name={row.team?.name} /> : null} */}

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            {belongsTo === 'Teams' ?
                                                                <div className={classes.teamProfile}>
                                                                    <span style={{ paddingBottom: '10px' }}>{`${row?.team?.name}`.toUpperCase()}</span>
                                                                </div >
                                                                :
                                                                <div className={classes.teamProfile}>
                                                                    <span style={{ paddingBottom: '10px' }}>{`${row?.player?.name}`.toUpperCase()}</span>
                                                                </div>

                                                            }
                                                            <div className={dynamicColumns.length > 3 ? classes.kpisContainer : classes.kpisContainer2}>
                                                                {/* <div style={{ width: '100px' }}> */}

                                                                {dynamicColumns.slice(0, 7).map((dynamicColumn, i) => (
                                                                    <>
                                                                        <div style={{ display: 'flex', margin: '0', flexDirection: 'column', width: '100px' }}>
                                                                            <span style={{ fontWeight: 'bold' }}>{dynamicColumn.title}</span>
                                                                            <span style={{ fontWeight: 500 }}>{tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).unit_of_measurement === 'CURRENCY' ? <GetCountryCurrencyFormat type="currency" value={tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).actual} /> : tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).unit_of_measurement === 'NUMBER' ?
                                                                                <GetCountryCurrencyFormat type="number" value={tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).actual} /> : tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).unit_of_measurement === 'PERCENTAGE' ? getNumberFormat(userDetails?.countryCode, tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).actual) + '%' : getNumberFormat(userDetails?.countryCode, tableData[rowIndex].kpis.find(kpi => kpi.kpi_id === dynamicColumn.field.split('-')[0]).actual)}</span>
                                                                        </div >
                                                                        <hr></hr>
                                                                    </>
                                                                ))}
                                                                {/* </div> */}

                                                            </div >
                                                        </div ></td >
                                                    <td>{getNumberFormat(userDetails?.countryCode, row?.totalPoints, decimalView)}</td>
                                                </tr >
                                            )
                                        }
                                    </tbody >
                                </table >
                                <div className={classes.kpiShortcode}>
                                    {dynamicColumns.slice(0, 7).map((kpi) =>
                                        <span style={{
                                            fontSize: '30px', color: heading?.color, whiteSpace: 'nowrap', margin: '5px 10px 5px 5px'
                                        }}><span>{kpi.title}</span> : <span style={{ fontFamily: 'BarlowCondensed-Regular', fontWeight: 'normal' }}>{kpi.kpiname}</span></span>
                                    )}
                                </div>
                            </div >
                        </div >

                    </>
                }
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({
    orgDetails: state.common.orgDetails,
    theme: state.common.templateTheme
});
const mapDispatchToProps = (dispatch) => {
    return {
        getUploadLogsInfo: (options) => dispatch(getUploadLogsInfo(options))

    }
};

export default connect(mapStateToProps, mapDispatchToProps, null)(PerformanceTableTemplate);

