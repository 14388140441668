import { all, call, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { CREATE_CHAT_MESSAGE, CREATE_NEW_CHAT_MESSAGES, GET_CHATS_LIST, GET_CHAT_MESSAGES_LIST, RATE_MESSAGE } from "../../ducks/chatgpt";
import { CHAT_MESSAGE_RATING_API, CREATE_CHAT, CREATE_CHATMESSAGES_LIST, GET_CHATMESSAGES_LIST, GET_CHAT_LIST } from "../../config";


const craeteNewChat = (requestObj) => {
    return Axios.post(CREATE_CHAT, { ...requestObj });
};

const fetchChatList = (params) => {
    return Axios.get(GET_CHAT_LIST, { params });
}


const fetchChatMessages = (chatId, params) => {
    return Axios.get(GET_CHATMESSAGES_LIST(chatId), { params });
}

const createChatMessages = (chatId, requestObj) => {
    return Axios.post(CREATE_CHATMESSAGES_LIST(chatId), { ...requestObj });
}

const messageRating = (msgId, requestObj) => {
    return Axios.put(CHAT_MESSAGE_RATING_API(msgId), { ...requestObj })
}

function* ChatGptcreateNewChat() {
    yield takeEvery(CREATE_CHAT_MESSAGE, function* createChat(action) {
        const { requestObj, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(craeteNewChat, requestObj);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};

function* ChatGptgetChats() {
    yield takeEvery(GET_CHATS_LIST, function* getChatsList(action) {
        const { params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchChatList, params);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};


function* ChatGptgetChatMessages() {
    yield takeEvery(GET_CHAT_MESSAGES_LIST, function* getChatMessagesList(action) {
        const { chatId, params, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(fetchChatMessages, chatId, params);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};

function* ChatGptcreateChatMessages() {
    yield takeEvery(CREATE_NEW_CHAT_MESSAGES, function* createNewChatMessages(action) {
        const { chatId, requestObj, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(createChatMessages, chatId, requestObj);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};


function* ChatGptMessageRating() {
    yield takeEvery(RATE_MESSAGE, function* rateMessages(action) {
        const { msgId, requestObj, onSuccessCB, onErrorCB } = action.payload;
        try {
            let { data, headers } = yield call(messageRating, msgId, requestObj);
            if (data) {
                yield call(onSuccessCB, data, headers);
            }
        }
        catch (e) {
            yield call(onErrorCB, e);
        }
    })
};


export default function* () {
    yield all([
        ChatGptgetChats(),
        ChatGptcreateNewChat(),
        ChatGptgetChatMessages(),
        ChatGptcreateChatMessages(),
        ChatGptMessageRating(),
    ])
}