import { makeConstant } from "./_helpers";

const constant = makeConstant("chase/chatgpt");

export const CREATE_CHAT_MESSAGE = constant('CREATE_CHAT_MESSAGE');
export const GET_CHATS_LIST = constant('GET_CHATS_LIST');
export const GET_CHAT_MESSAGES_LIST = constant('GET_CHAT_MESSAGES_LIST');
export const CREATE_NEW_CHAT_MESSAGES = constant('CREATE_NEW_CHAT_MESSAGES');
export const RATE_MESSAGE = constant('RATE_MESSAGE');



export const createChat = (payload) => ({
    type: CREATE_CHAT_MESSAGE,
    payload
})

export const getChatsList = (payload) => ({
    type: GET_CHATS_LIST,
    payload
})

export const getChatMessagesList = (payload) => ({
    type: GET_CHAT_MESSAGES_LIST,
    payload
})

export const createNewChatMessages = (payload) => ({
    type: CREATE_NEW_CHAT_MESSAGES,
    payload
})

export const rateMessages = (payload) => ({
    type: RATE_MESSAGE,
    payload
})


const initialState = {};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {

        default:
            return state;
    }
};
