import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { TextField, makeStyles } from '@material-ui/core';
import SeasonFilters from '../SeasonFilters';
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getPlayersPointsList, getSeasonKpisList, getSeasonTeams, getSeasonPlayersRoles, getRegionsList, getDivisionsList } from '../../../ducks/game';
import { setLoading } from "../../../ducks/loading";
import { TabBar } from '../TabBar';
import gameIllustration from '../../../assets/images/game_Illustration.png';
import LeaderboardTable from './LeaderboardTable';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import { commonColumnOptions } from '../../PointsTables/TeamPointsTable';
import { getNumberFormat, decodeEnum, getCurrencyFormat, getDurationWithUnit, setSearchQuery, getSearchValue, downloadFiles, historyPushWithSearch, seasonKpiPositionsSort } from '../../../helpers';
import { Grid } from '@material-ui/core';
import IllustrationView from '../../LiveScores/IllustrationView';
import { CustomListGroup } from '../../LiveScores/RoundsListView';
import { DecimalViewSwitch } from '../../LiveScores/CustomSwitch';
import PlayerLeaderboardTemplate from '../../GlobalComponents/PlayerLeaderboardTemplate';
import { toJpeg } from 'html-to-image';
import { resizeImageToSquare } from '../../ArenaComponents/cropImage';
import { openCreateArenaDialog } from '../../../ducks/arena';
import GetCountryCurrencyFormat from '../../GlobalComponents/GetCountryCurrencyFormat';
import InfiniteScroll from 'react-infinite-scroll-component';
import SeasonLabelsCommon from '../../GlobalComponents/SeasonLabelsCommon';
import { Autocomplete } from '@material-ui/lab';
import { leaderBoardFilterBy } from '..';


const useStyles = makeStyles((theme) => ({
    nameRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    filterSec: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    autocompleteRoot: {
        width: "160px",
        height: "40px",
        marginRight: "10px",
        borderRadius: 5,
        overflow: "clip",
        whiteSpace: "nowrap",
        background: 'white',
        "& .MuiInputBase-root": {
            overflow: "hidden !important",
        },
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    division: null
};
const pagination = {
    limit: 5,
    page: 0,
}
const subTabs = [{ label: 'Core KPI', value: 0, icon: '', path: 'core' }, { label: 'Bonus KPI', value: 1, icon: '', path: 'bonus' }, { label: 'Team', value: 2, icon: '', path: 'team' }, { label: 'Role', value: 3, icon: '', path: 'role' }, { label: 'Region', value: 4, icon: '', path: 'region' }, { label: 'By Division', value: 5, icon: '', path: 'division' }]

const PlayerLeaderboard = ({ history, filtersData, location, setLoading, loading, getSeasonKpisList, getSeasonTeams, getSeasonPlayersRoles, getRegionsList, userDetails, columnskpi, tabDatakpi, datakpi, userDetailskpi, filersDatakpi, openCreateArenaDialog, getDivisionsList, seasonDetails, seasonKpiPostions, seasonRoles, seasonDivisions, seasonRegions, seasonTeams, leaderboardFilter, setLeaderboardFilter }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    // const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [subTabsData, setSubTabsData] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);
    const [viewType, setViewType] = useState('CARD');
    const [selectedTab, setSelectedTab] = useState(null);
    const [decimalView, setDecimalView] = useState(false);
    const [kpiTabData, setKpiTabData] = useState({})
    const [kpiData, setKpiData] = useState([])
    const [canDownload, setCanDownload] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [divisionsData, setDivisionsData] = useState([]);
    const [viewPageType, setViewPageType] = useState('CARD');
    // const [leaderboardFilter, setLeaderboardFilter] = useState({
    //     // filterBy: leaderBoardFilterBy[0],
    //     roles: null,
    //     teams: null,
    //     regions: null,
    //     divisions: null
    // });
    const [seasonKpiList, setSeasonKpiList] = useState([])


    useEffect(() => {
        if (filtersData?.season?._id) {
            getSeasonKpis(filtersData.season?._id, location.pathname.split('/')[4]);
        }
    }, [filtersData?.season?._id])

    // useEffect(() => {
    //     if (seasonDetails?.player_role_id) setLeaderboardFilter({ ...leaderboardFilter, roles: getSearchValue(history, 'role') ? seasonRoles?.find(obj => obj?._id === getSearchValue(history, 'role')) : seasonRoles.find(el => el._id === seasonDetails?.player_role_id) });
    // }, [seasonRoles, seasonDetails?.player_role_id])

    // useEffect(() => {
    //     if (location.pathname) {
    //         setLeaderboardFilter({ ...leaderboardFilter, filterBy: leaderBoardFilterBy.find(tab => tab.path === location.pathname.split('/')[5]) });
    //         // setCurrentTab(leaderBoardFilterBy.find(tab => tab.path === location.pathname.split('/')[5])?.value);
    //         // setCurrentTab(subTabs.findIndex(tab => tab.path === location.pathname.split('/')[5]));
    //     }
    // }, [location.pathname]);

    // useEffect(() => {
    //     if (filtersData.season) {
    //         if (filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints) {
    //             setSubTabsData([]);
    //             setCurrentTab(0);
    //         }
    //         else {
    //             if ((currentTab <= 1 && (location.pathname.split('/')[5] === 'core' || location.pathname.split('/')[5] === 'bonus'))) {
    //                 getSeasonKpis(filtersData.season._id, currentTab);
    //             }
    //             else if (currentTab === 2) {
    //                 getSeasonTeamsList(filtersData.season._id);
    //             }
    //             else if (currentTab === 3) {
    //                 getRolesList(filtersData.season._id);
    //             }
    //             else if (currentTab === 5) {
    //                 if (filtersData.season?.divisions_enabled) getDivisions(filtersData.season._id);
    //                 else setSubTabsData([]);
    //             }
    //             else if (currentTab === 4) {
    //                 getListOfRegions();
    //             }
    //             else {

    //             }
    //         }
    //     }
    // }, [filtersData.season, currentTab]);


    const handleDownloadClick = (kpiTabData, kpiData, type, handleShareMenuClose, tabType, leaderboardFilter) => {
        setKpiTabData(kpiTabData)
        setKpiData(kpiData)
        handleDownloadPerformance(`kpi-perfomances-table-${kpiTabData._id}`, `kpi_performance_${filtersData.season?.uid}_${kpiTabData.name}`, type, handleShareMenuClose, kpiTabData._id, tabType, leaderboardFilter);
    }

    const handleDownloadPerformance = async (id, title, type, handleShareMenuClose, kpiId, tabType, role_id) => {
        await setCanDownload(true);
        toJpeg(document.getElementById(id))
            .then((dataUrl) => {
                setCanDownload(false);
                try {
                    const img = new Image();
                    img.onload = async function () {
                        let high = (this.width > this.height) ? 'width' : 'height';
                        // console.log('IMage 2 ', high, this[high], this.width, this.height);
                        const resultImage = (this.width > 0 && this.height > 0) ? await resizeImageToSquare(dataUrl, { width: this[high], height: this[high] }) : null;
                        if (resultImage) {
                            if (type === 'download') {
                                downloadFiles(resultImage, title.replace(".", ""));
                                handleShareMenuClose()
                            }
                            else {
                                openCreateArenaDialog({
                                    open: true, arena: {}, stepType: 'crop', attachment: [
                                        { url: resultImage, mimeType: "IMAGE", width: 4, height: 4, fileName: "kpi_performance_HHD-49_kpi_ck_bk -2022-08-18T133448.437.png", fileType: "image/png" }],
                                    postDetails: {
                                        targetPage: "PLAYER_LEADERBOARD",
                                        details: {
                                            league: filtersData?.league?._id,
                                            season: filtersData?.season?._id,
                                            round: null,
                                            match: null,
                                            subTab: {
                                                tab: tabType === 'CORE KPI' ? 'CORE_KPI' : tabType === 'BONUS KPI' ? 'BONUS_KPI' : tabType, // CORE_KPI, BONUS_KPI, TEAM, ROLE and REGION 
                                                value: kpiId, // KPI_ID, TEAM_ID, ROLE_ID and REGION_ID
                                            },
                                            division: leaderboardFilter?.divisions ? leaderboardFilter?.divisions?._id : null,
                                            roleId: leaderboardFilter?.roles ? leaderboardFilter?.roles?._id : null,
                                            regionId: leaderboardFilter?.regions ? leaderboardFilter?.regions?._id : null,
                                        }
                                    }
                                });
                                handleShareMenuClose()
                            }
                        }
                    }
                    img.src = dataUrl;
                    return img.onload();
                } catch (e) {
                }
                // downloadFiles(dataUrl, title.replace(".", ""));
            })
    }





    const handleSubTabChange = (newValue) => {
        if (newValue?.value !== currentTab) {
            historyPushWithSearch(`${path}/${newValue?.path}`, history, location.search);
            setCurrentTab(newValue?.value);
            setSubTabsData([]);
            setViewPageType('CARD');
        }

    }

    const handleViewChange = (viewType, tab, type, role) => {
        if (viewType === 'PAGE') {
            setViewPageType('PAGE');
            // ['core', 'bonus'].includes(type) && role && setSearchQuery(history, { role: role });
            history.push(`${path}/${type}/${tab?._id}${history.location?.search}`);
            // historyPushWithSearch(`${path}/${type}/${tab?._id}`, history, location.search);
        }
        else {
            setViewPageType('CARD');
            historyPushWithSearch(`${path}/${type}`, history, location.search);
        }
    }

    const getSeasonKpis = (seasonId, currentTab) => {
        setLoading(true);
        getSeasonKpisList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, tags: currentTab === 'core' ? 'PLAYER_MATCH' : 'PLAYER_BONUS' },
            onSuccessCB: (result, headers) => {
                if (result) {
                    let kpis_attributes = seasonKpiPositionsSort(result, seasonKpiPostions, '_id', 'detailed_hidden');
                    setSubTabsData(kpis_attributes);
                    setSeasonKpiList(kpis_attributes);
                }
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Seasons', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    };

    const getSeasonTeamsList = (seasonId) => {
        setLoading(true);
        getSeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map(ele => {
                    ele.label = ele.name;
                    ele._id = ele._id;
                    return ele;
                });
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const getRolesList = (seasonId) => {
        setLoading(true);
        getSeasonPlayersRoles({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    ele._id = ele.role_id;
                    return ele;
                })
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getListOfRegions = () => {
        setLoading(true);
        getRegionsList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    return ele;
                })
                setSubTabsData([...result]);
                setViewType('CARD');
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    }

    const getDivisions = (seasonId) => {
        setLoading(true);
        setSubTabsData([]);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const getDivisionMore = () => {
        let seasonId = filtersData?.season?._id;
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: pagination.limit, page: pagination.page + 1 },
            onSuccessCB: onDivisionSuccessCB,
            onErrorCB: onDivisionErrorCB
        })
    }

    const onDivisionSuccessCB = (result, headers) => {
        if (result) {
            result = result.map(ele => {
                ele.label = ele.name;
                ele._id = ele._id;
                return ele;
            });
            setSubTabsData(prev => ([...prev, ...result]));
            setViewType('CARD');
            setDivisionsData([]);
            setDivisions([...result]);
            setLoading(false);
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
        }
        setLoading(false);
    }

    const onDivisionErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    // //filters on changes
    // const handleChange = (data, field) => {
    //     if (field === 'filterBy') {
    //         if (data?.value !== currentTab) {
    //             historyPushWithSearch(`${path}/${data?.path}`, history, location.search);
    //             setCurrentTab(data?.value);
    //             setSubTabsData([]);
    //             setViewPageType('CARD');
    //             setLeaderboardFilter({ ...leaderboardFilter, [field]: data });
    //         }
    //     }
    //     else {
    //         setLeaderboardFilter({ ...leaderboardFilter, [field]: data });
    //         setSearchQuery(history, { role: data?._id });
    //     }

    // };

    const columns = (kpi) => [
        {
            name: "rank", label: "Rank", options: commonColumnOptions
        },
        {
            name: "player", label: "Player", hidden: true, options: {
                hidden: true,
                customBodyRender: (player) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent data={player} from='PLAYER' type="PLAYER" name={player.name} username={player._id} url={player.photo_url} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                        {player && player.name}
                    </div>

                )
            }
        },
        {
            name: "team", label: "Team", options: {
                display: filtersData.season?.season_format !== 'PLAYER_RACE',
                customBodyRender: (team) => (
                    <div className={classes.nameRoot}>
                        <AvatarComponent type='TEAM' name={team?.name} username={team?._id} url={team?.logo_url} onClick={() => { }} style={{ marginRight: 14 }} filtersData={filtersData} />
                        {team && team.name}
                    </div>

                )
            }
        },
        {
            name: "target", label: "Target", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? (!(kpi?.copy_detailed_attributes?.includes('TARGET')) || (currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
                // customBodyRender: target => (target ? kpi.unit_of_measurement === 'CURRENCY' ?
                //     <GetCountryCurrencyFormat type="currency" value={target} />
                //     : kpi.unit_of_measurement === 'NUMBER' ?
                //         <GetCountryCurrencyFormat type="number" value={target} />
                //         // getCurrencyFormat(userDetails?.countryCode, kpi.unit, target)
                //         : `${getNumberFormat(userDetails?.countryCode, target)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(target, kpi.unit) : ''}`
                //     : 0)
            }
        },
        {
            name: "actual", label: "Actual", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
                // customBodyRender: actual => (actual ? kpi.unit_of_measurement === 'CURRENCY' ?
                //     <GetCountryCurrencyFormat type="currency" value={actual} />
                //     : kpi.unit_of_measurement === 'NUMBER' ?
                //         <GetCountryCurrencyFormat type="number" value={actual} />
                //         // getCurrencyFormat(userDetails?.countryCode, kpi.unit, actual)
                //         : `${getNumberFormat(userDetails?.countryCode, actual)} ${kpi.unit_of_measurement === 'PERCENTAGE' ? '%' : kpi.unit_of_measurement === 'DURATION' ? getDurationWithUnit(actual, kpi.unit) : ''}`
                //     : 0)
            }
        },
        {
            name: "pending_actual", label: "Pending Actual", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PENDING_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "achieved", label: "Achievement %", options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('ACHIEVEMENT')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "planned_target", label: kpi?.achievement_plan_enabled && kpi?.achievement_plan?.frequency === 'WEEKLY' ? 'WTD Target' : 'MTD Target',
            options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? (!(kpi?.copy_detailed_attributes?.includes('PLANNED_TARGET')) || (currentTab >= 2) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_actual", label: kpi?.achievement_plan_enabled && kpi?.achievement_plan?.frequency === 'WEEKLY' ? 'WTD Actual' : 'MTD Actual', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_pending_actual", label: kpi?.achievement_plan_enabled && kpi?.achievement_plan?.frequency === 'WEEKLY' ? 'WTD Pending Actual' : 'MTD Pending Actual', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_PENDING_ACTUAL')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true
            }
        },
        {
            name: "planned_achievement", label: kpi?.achievement_plan_enabled && kpi?.achievement_plan?.frequency === 'WEEKLY' ? 'WTD Ach%' : 'MTD Ach%', options: {
                ...commonColumnOptions,
                display: viewPageType === 'CARD' ? ((currentTab >= 2) || !(kpi?.copy_detailed_attributes?.includes('PLANNED_ACHIEVEMENT')) || (kpi.unit_of_measurement === 'PERCENTAGE' || kpi.unit_of_measurement === 'DURATION')) ? false : true
                    : (currentTab >= 2) ? false : true,
                customBodyRender: achieved => getNumberFormat(userDetails.countryCode, achieved || 0) + '%'
            }
        },
        {
            name: "match_points", label: `${seasonDetails?.labels_config?.kpis?.core} Pts`, options: {
                ...commonColumnOptions, display: currentTab >= 2,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
        {
            name: "bonus_points", label: `${seasonDetails?.labels_config?.kpis?.bonus} Pts`, options: {
                ...commonColumnOptions, display: currentTab >= 2,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
        {
            name: "total_points",
            label: currentTab <= 1 ? ((subTabs[currentTab]?.label.toUpperCase()) === 'CORE KPI' ? `${seasonDetails?.labels_config?.kpis?.core} Pts` : `${seasonDetails?.labels_config?.kpis?.bonus} Pts`) : "Total Pts",
            options: {
                ...commonColumnOptions,
                customBodyRender: (totalpoints) => getNumberFormat(userDetails?.countryCode, totalpoints, decimalView),
            },
        },
    ];

    const cardsList = (subTabsData, type) => (
        <div>
            {
                subTabsData.length > 0 ?
                    <>
                        {type !== "division" ?
                            <Grid container spacing={2} style={{ marginTop: 0 }} >
                                {
                                    subTabsData.map((tab, i) => (
                                        <>
                                            <PlayerLeaderboardTemplate
                                                id={`kpi-perfomances-table-${kpiTabData?._id}`}
                                                filtersData={filtersData}
                                                belongsTo='Players'
                                                columns={columns(kpiTabData)}
                                                tabData={kpiTabData}
                                                userDetails={userDetails}
                                                data={kpiData}
                                                render={(canDownload)}
                                                tabType={subTabs[currentTab]?.label.toUpperCase()}
                                                decimalView={decimalView}

                                            />


                                            <Grid key={i} item md={subTabsData.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>

                                                <LeaderboardTable setViewPageType={setViewPageType} type={type} viewType={"CARD"} filtersData={filtersData} columns={columns(tab)} tabData={tab} groupTab={currentTab} handleViewChange={handleViewChange} loading={loading} handleDownloadClick={handleDownloadClick} tabType={(location.pathname.split('/')[4] === 'core') ? 'CORE KPI' : 'BONUS KPI'} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>
                            :
                            < InfiniteScroll
                                dataLength={subTabsData.length}
                                next={getDivisionMore}
                                hasMore={subTabsData.length !== pagination.total_count}
                                scrollableTarget="leaderboard_data"
                                style={{ overflow: 'hidden' }}
                            >
                                <Grid container spacing={2} style={{ marginTop: 0 }} >
                                    {
                                        subTabsData.map((tab, i) => (
                                            <>
                                                <PlayerLeaderboardTemplate
                                                    id={`kpi-perfomances-table-${kpiTabData?._id}`}
                                                    filtersData={filtersData}
                                                    belongsTo='Players'
                                                    columns={columns(kpiTabData)}
                                                    tabData={kpiTabData}
                                                    userDetails={userDetails}
                                                    data={kpiData}
                                                    render={(canDownload)}
                                                    tabType={subTabs[currentTab]?.label.toUpperCase()}
                                                    decimalView={decimalView}

                                                />
                                                <Grid key={i} item md={subTabsData.length === 1 ? 12 : 6} xs={12} style={{ marginTop: 10 }}>

                                                    <LeaderboardTable setViewPageType={setViewPageType} type={type} viewType={"CARD"} filtersData={filtersData} columns={columns(tab)} tabData={tab} groupTab={currentTab} handleViewChange={handleViewChange} loading={loading} handleDownloadClick={handleDownloadClick} tabType={(location.pathname.split('/')[4] === 'core') ? 'CORE KPI' : 'BONUS KPI'} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                                                </Grid>
                                            </>
                                        ))
                                    }
                                </Grid>
                            </InfiniteScroll>
                        }
                    </>
                    :
                    < IllustrationView loading={loading} style={{ marginTop: '50px' }} />
            }
        </div>
    )
    return (
        <>
            {
                filtersData.season?.season_format === 'TEAM_RACE' && !filtersData.season?.auto_player_sprints ?
                    <div style={{ textAlign: 'center', marginTop: 40 }}>
                        <img src={gameIllustration} />
                        <p style={{ fontSize: 14, color: '#0000008A' }}>No player data is available, since player performance is not measured as a part of the selected season, which is of the format ’Team race’</p>
                    </div>
                    :
                    <div style={{ margin: '10px 0' }}>
                        {/* <TabBar tabs={subTabs} subTabVal={currentTab} handleSubTabChange={handleSubTabChange} /> */}
                        {/* <CustomListGroup data={subTabs.map(ele => { return { ...ele, label: (ele.path === 'core') ? seasonDetails?.labels_config?.kpis[ele.path] : (ele.path === 'bonus') ? seasonDetails?.labels_config?.kpis[ele.path] : ele.label } })} selectedItem={subTabs.find((option) => option.value === currentTab)} onClick={handleSubTabChange} style={{ marginLeft: 0, maxWidth: 'max-content' }} /> */}
                        {
                            // (viewPageType === 'CARD') &&
                            // <div className={classes.filterSec}>
                            //     <div style={{ margin: '8px 0px', display: 'flex' }}>
                            //         <>
                            //             {/* <Autocomplete
                            //                 id="filterBy"
                            //                 options={leaderBoardFilterBy}
                            //                 getOptionLabel={(option) => option.name}
                            //                 value={leaderboardFilter?.filterBy ? leaderboardFilter?.filterBy : leaderboardFilter[0]}
                            //                 disableClearable
                            //                 classes={{ inputRoot: classes.autocompleteRoot }}
                            //                 renderInput={(params) => <TextField {...params} label={'Filter By'} variant="outlined" size="small" />}
                            //                 onChange={(...args) => handleChange(args[1], 'filterBy')}
                            //             /> */}
                            //             {/* {leaderboardFilter?.filterBy && ['core', 'bonus'].includes(location.pathname.split('/')[5]) && */}
                            //             <Autocomplete
                            //                 id="roles"
                            //                 clearable
                            //                 options={seasonRoles}
                            //                 getOptionLabel={(option) => option.name}
                            //                 value={leaderboardFilter?.roles}
                            //                 classes={{ inputRoot: classes.autocompleteRoot }}
                            //                 renderInput={(params) => <TextField {...params} label={'Roles'} variant="outlined" size="small" />}
                            //                 onChange={(...args) => handleChange(args[1], 'roles')}
                            //             />
                            //             {/* }/ */}
                            //         </>
                            //     </div>
                            //     <div>
                            //         <p className="table-title decimal-switch-root" >
                            //             <p></p>
                            //             <DecimalViewSwitch checked={decimalView} onChange={() => setDecimalView(!decimalView)} />
                            //         </p>
                            //     </div>
                            // </div>
                        }

                        <Switch>
                            {/* <Route exact path={`${path}/core/:id`}>
                                <LeaderboardTable type="core" setViewPageType={setViewPageType} viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Route exact path={`${path}/core`}>
                                {cardsList(subTabsData, "core")}
                            </Route>
                            <Route exact path={`${path}/bonus/:id`}>
                                <LeaderboardTable type="bonus" setViewPageType={setViewPageType} viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Route exact path={`${path}/bonus`}>
                                {cardsList(subTabsData, "bonus")}
                            </Route>
                            <Route exact path={`${path}/team/:id`}>
                                <LeaderboardTable type="team" viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Route exact path={`${path}/team`}>
                                {cardsList(subTabsData, "team")}
                            </Route>
                            <Route exact path={`${path}/role/:id`}>
                                <LeaderboardTable type="role" setViewPageType={setViewPageType} viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Route exact path={`${path}/role`}>
                                {cardsList(subTabsData, "role")}
                            </Route>
                            <Route exact path={`${path}/region/:id`}>
                                <LeaderboardTable type="region" setViewPageType={setViewPageType} viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Route exact path={`${path}/region`}>
                                {cardsList(subTabsData, "region")}
                            </Route>
                            <Route exact path={`${path}/division`}>
                                {cardsList(subTabsData, "division")}
                            </Route>
                            <Route exact path={`${path}/division/:id`}>
                                <LeaderboardTable type="division" viewType={"PAGE"} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route> */}
                            <Route exact path={`${path}/kpi`}>
                                {cardsList(seasonKpiList, "kpi")}
                            </Route>
                            <Route exact path={`${path}/kpi/:id`}>
                                <LeaderboardTable type="kpi" viewType={"PAGE"} setViewPageType={setViewPageType} groupTab={currentTab} tabType={subTabs[currentTab]?.label.toUpperCase()} filtersData={filtersData} columns={columns} dataList={subTabsData} handleViewChange={handleViewChange} loading={loading} seasonDetails={seasonDetails} seasonRoles={seasonRoles} roleFilterId={leaderboardFilter?.roles?._id} leaderboardFilter={leaderboardFilter} setLeaderboardFilter={setLeaderboardFilter} />
                            </Route>
                            <Redirect to={{ pathname: `${path}/kpi`, search: location.search }} />
                        </Switch>
                    </div>
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    userDetails: state.user,
    loading: state.loading,
    seasonDetails: state.game.seasonDetails,
    seasonKpiPostions: state.game.seasonKpiPostions
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPointsList: (options) => dispatch(getPlayersPointsList(options)),
        getSeasonKpisList: (options) => dispatch(getSeasonKpisList(options)),
        getSeasonTeams: (options) => dispatch(getSeasonTeams(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
        getRegionsList: (options) => dispatch(getRegionsList(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options))
    }
};


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayerLeaderboard));
