import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, InputBase, List, ListItem, ListItemText, Menu, MenuItem, Typography, fade } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { getAvatarText, getDurationWithUnit, getEllipsisText, getNumberFormat, getSearchValue, historyPushWithSearch, setSearchQuery } from "../../helpers";
import { getLeaguesList, getSeasonsList, getRoundsList, getMatchesList, getLoggedInUserRole, getCountryCurrency, getSingleSeasonDetails, getLeaguesSeasonsList, getDivisionsList, getSeasonKpiPositionList, getUserHierarchies, getSeasonLiveMatchType, getMySeasonTeams, getRegionsList, getBreadcrumsData, getSeasonPlayersRoles, getSeasonTeams } from "../../ducks/game";
import { Redirect, Route, Switch, useRouteMatch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoading } from "../../ducks/loading";
import MentorDashboard from "./MentorDashboard";
import PlayerDashboard from "./PlayerDashboard";
import NonPlayerDashboard from "./NonPlayerDashboard";
import { Fade } from "@material-ui/core";
import Dashboard from "./Dashboard";
import LiveScores from "../LiveScores";
import PointsTables from "../PointsTables";
import PerformanceStats from "../PerformanceStats";
import Leaderboards from "../Leaderboards";
import MatchDetails from "../LiveScores/MatchDetails";
import LiveMatches from "./LiveMatches";
import AllMatches from "./AllMatches";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShareLiveScoreDialog from "../LiveScores/ShareLiveScoreDialog";
import CreatePost from "../ArenaComponents/CreatePost";
import { getTemplateTheme } from "../../ducks/common";
import { ChaseonsSkeleton } from "../Arena/ReactionsDialog";
import DashboardNew from "./version1/DashboardNew";
import SearchIcon from '@material-ui/icons/Search';
import IllustrationView from "../LiveScores/IllustrationView";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import GetCountryCurrencyFormat from "../GlobalComponents/GetCountryCurrencyFormat";
import Skeleton from "@material-ui/lab/Skeleton";
import { debounce } from "underscore";
import qs from 'query-string';
import { ChevronLeft } from "@material-ui/icons";
import Rewards from "../Rewards";
import SeasonRules from "../SeasonRules";



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F0F2F5",
        width: "100%",
        maxHeight: "calc(100vh - 116px)",
        // maxHeight: 'calc(100vh - 100px)',
        overflowY: "auto",
        padding: "56px 24px 0px",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            // maxHeight: 'calc(100vh - 80px)',
            // maxWidth: `calc(100vw - 150px)`,
            padding: "56px 0px 0px",
        },
    },
    mainroot: {
        // '& .MuiTabs-indicator': { width: '120px !important' }
    },

    input: {
        color: "#CCCDFD",
        backgroundColor: "#CCCDFD",
        "&:focus": {
            borderRadius: 4,
            borderColor: "#CCCDFD",
            boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
            background: "rgba(0,0,0,0)"
        }
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        margin: '20px 45px 15px 45px',
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginLeft: 15
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    tabsContainerStyles: {
        // justifyContent: 'center',
        marginLeft: 34
    },
    tabsRoot: {
        // position: 'fixed',
        // top: 60,
        // left: 0,
        // backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 40,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        boxShadow: '-4px 3px 10px -7px #000'
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        color: '#262626',
        minWidth: 'auto',
        // margin: '0px 12px',
        padding: '6px 12px 0px',
        fontSize: 13,
    },
    selectedTab: {
        fontWeight: 600,
    },
}));

const defaultFilters = {
    league: null,
    season: null,
    round: null,
    status: 'LIVE',
    match: null,
    role: null,
    division: null,
    liveMatchType: null,
    team: null,
    matches: {}
};

const MenuOptions = [
    { id: 'PERFORMANCE', name: 'Performance', pathname: 'performance' },
    // { id: 'DASHBOARD', name: 'Dashboard', pathname: 'dashboard' },
    { id: 'LIVE_SCORES', name: 'Live Score', pathname: 'live-scores' },
    { id: 'POINTS_TABLE', name: 'Points Table', pathname: 'points-table' },
    // { id: 'ALL_MATCHES', name: 'All Matches', pathname: 'matches' },
    { id: 'STATS', name: 'Stats', pathname: 'stats' },
    { id: 'TRENDS', name: 'Trends', pathname: 'trends' },
    { id: 'LEADERBOARD', name: 'Leaderboard', pathname: 'leaderboard' },
    { id: 'Rewards', name: 'Rewards', pathname: 'rewards' },
    { id: 'Season Rules', name: 'Season Rules', pathname: 'season-rules' },


];

const leagueTabs = [
    { id: 'live', name: 'Live', value: 0 },
    { id: 'draft', name: 'Draft', value: 1 },
    { id: 'completed', name: 'Closed', value: 2 },
]
let pagination = { page: 0, limit: 12 };

const LeaguesIndex = ({ history, seasonDetails, location, getLeaguesList, getLeaguesSeasonsList, getRoundsList, getMatchesList, getOverallOrgStats, getLoggedInUserRole, getSingleSeasonDetails, getSeasonsList, getCountryCurrency, getTemplateTheme, userDetails, getDivisionsList, getSeasonKpiPositionList, getUserHierarchies, keycloak, getSeasonLiveMatchType, getMySeasonTeams, getRegionsList, getBreadcrumsData, getSeasonPlayersRoles, getSeasonTeams }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState('DASHBOARD');
    const [filtersData, setFiltersData] = useState({ ...defaultFilters });
    const [leagues, setLeagues] = useState([]);
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [seasons, setSeasons] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [menuPosition, setMenuPosition] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [leagueSearch, setLeagueSearch] = useState();
    const [leagueTab, setLeagueTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const qryparams = qs.parse(history.location.search);
    const [divisions, setDivisions] = useState([]);
    const [matches, setMatches] = useState();
    const [userHierarchieRoles, setUserHierarchieRoles] = useState([]);
    const [teams, setTeams] = useState([]);
    const [seasonRegions, setSeasonRegions] = useState([]);
    const [seasonTeams, setSeasonTeams] = useState([]);

    const isAdmin = keycloak.decoded_token.realm_access.roles &&
        keycloak.decoded_token.realm_access.roles.length > 0 &&
        keycloak.decoded_token.realm_access.roles.includes('admin');

    useEffect(() => {
        getBreadcrumsData([]);
        getSeasonDetail(qryparams ? qryparams?.season : filtersData?.season?._id);
        getDivisions(qryparams ? qryparams.season : filtersData.season?._id);
        getSeasonKpiPositions(qryparams ? qryparams.season : filtersData.season?._id);
        getTeamsList(qryparams ? qryparams.season : filtersData.season?._id);
        getSeasonTeamsList(qryparams ? qryparams.season : filtersData.season?._id);
        getTemplateTheme();
        getListOfRegions();
        // getUserHierarchiesList();
        let seasonId = qryparams ? qryparams.season : filtersData.season?._id
        getLoggedInUserRole({ seasonId, onSuccessCB, onErrorCB });
    }, []);

    useEffect(() => {
        if (filtersData?.season) getSeasonLiveMatch(qryparams ? qryparams.season : filtersData.season?._id);
    }, [filtersData?.season])


    const getSeasonDetail = (seasonId) => {
        getSingleSeasonDetails({
            seasonId: seasonId,
            onSuccessCB: (result) => {
                getUserHierarchiesList(result?.player_role, seasonId)
                setFiltersData(prev => ({ ...prev, league: { ...result, _id: result.league_id, name: result.league_name, logo_url: result.logo_url } }));
                const searchValue = getSearchValue(history, 'season');
                !searchValue && setSearchQuery(history, { season: result?._id });
                setFiltersData(prevState => ({ ...prevState, season: result, round: null }));
                setSelectedSeason(result);
                getMatches(result?._id, result?.season_format === 'TEAM_RACE' ? (result?.auto_player_sprints ? ['TEAM_SPRINT', 'PLAYER_SPRINT'] : 'TEAM_SPRINT') : 'PLAYER_SPRINT');

            }, onErrorCB: (error) => { }
        })
    }

    // ----------get divisions------
    const getDivisions = (seasonId) => {
        setLoading(true);
        getDivisionsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setDivisions([...result]);
                // setSearchQuery(history, { division: result[0]?._id });
                setFiltersData(prev => ({ ...prev, division: result[0]?._id }));
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Divisions', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    // ---user hierarchies----
    const getUserHierarchiesList = (role, season) => {
        if (role === 'VIEWER') {
            getSeasonPlayersRoles({
                seasonId: season,
                params: { limit: 5000, page: 1 },
                onSuccessCB: (result, headers) => {
                    result = result.map((ele) => {
                        ele.label = ele.name;
                        ele._id = ele.role_id;
                        return ele;
                    })
                    setUserHierarchieRoles(result);
                    setFiltersData(prev => ({ ...prev, role: result[0]?._id }));
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            });
        }
        else {
            getUserHierarchies({
                seasonId: season,
                params: { limit: 5000, page: 1 },
                onSuccessCB: (result) => {
                    const finalRes = result.map((d => ({ name: d.role, _id: d._id, level: d.level, user: d.name, username: d.username })))
                    setUserHierarchieRoles(finalRes);
                    // setSearchQuery(history, { role: finalRes[0]?._id });
                    setFiltersData(prev => ({ ...prev, role: finalRes[0]?._id }));
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch User Roles', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            })
        }
    }

    //-----get live match api
    const getSeasonLiveMatch = (seasonId) => {
        if (filtersData?.season?.season_format) {
            getSeasonLiveMatchType({
                seasonId: seasonId,
                params: { limit: 5000, page: 1 },
                //type: (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.divisions_enabled) ? ['TEAM', 'PLAYER'] : filtersData?.season?.season_format === 'TEAM_RACE' ? 'TEAM' : 'PLAYER',
                onSuccessCB: (result) => {
                    setFiltersData(prev => ({ ...prev, matches: result }));
                    setFiltersData(prev => ({ ...prev, liveMatchType: filtersData.season?.season_format === 'TEAM_RACE' ? result?.TEAM_SPRINT?._id : result?.PLAYER_SPRINT?._id }));
                    setSearchQuery(history, { match: filtersData.season?.season_format === 'TEAM_RACE' ? result?.TEAM_SPRINT?._id : result?.PLAYER_SPRINT?._id });
                    setLoading(false);
                }, onErrorCB: (error) => {
                    setLoading(false);
                    enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Season Live Matches', {
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            })
        }
    }

    //----------get teams list
    const getTeamsList = (seasonId) => {
        getMySeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setFiltersData(prevState => ({ ...prevState, team: result[0]?._id }));
                    setTeams([...result]);
                }
                else {
                    setTeams([]);
                }
            }, onErrorCB: (error) => {
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Teams', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    //season teams
    const getSeasonTeamsList = (seasonId) => {
        setLoading(true);
        getSeasonTeams({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasonTeams([...result]);
                }
                else {
                    setSeasonTeams([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    //    regions list
    const getListOfRegions = () => {
        getRegionsList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                result = result.map((ele) => {
                    ele.label = ele.name;
                    return ele;
                })
                setSeasonRegions([...result]);
            },
            onErrorCB: (error) => { }
        });
    }


    //-----get matches for first id
    const getMatches = (seasonId, type) => {
        setLoading(true);
        getMatchesList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1, type },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    const searchValue = getSearchValue(history, 'match');
                    const finalResult = result.map(obj => ({ ...obj, label: obj?.title, value: obj?._id, pathname: 'match' }));
                    if (filtersData?.season?.season_format === 'TEAM_RACE' && filtersData?.season?.auto_player_sprints) {
                        const teamMatches = finalResult?.filter(obj => obj.type === 'TEAM_SPRINT');
                        const playerMatches = finalResult?.filter(obj => obj.type === 'PLAYER_SPRINT');
                        // !searchValue && setSearchQuery(history, { match: (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0])?._id });
                        let finalSearchValue = (teamMatches?.find(obj => obj._id === searchValue));
                        let finalSearchValuePlayer = (playerMatches?.find(obj => obj._id === searchValue))
                        setFiltersData(prevState => ({ ...prevState, match: finalSearchValue == undefined ? (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0]) : finalSearchValue, playermatch: finalSearchValuePlayer == undefined ? (playerMatches?.find(obj => obj.status === 'live') || playerMatches[0]) : finalSearchValuePlayer }));
                        // !searchValue && setSearchQuery(history, { match: finalSearchValue == undefined ? (teamMatches?.find(obj => obj.status === 'live')?._id || teamMatches[0]?._id) : finalSearchValue });
                        setMatches(finalSearchValue == undefined ? (teamMatches?.find(obj => obj.status === 'live') || teamMatches[0]) : finalSearchValue)
                        return;
                    }
                    else {
                        // !searchValue && setSearchQuery(history, { match: (finalResult?.find(obj => obj.status === 'live') || finalResult[0])?._id });
                    }
                    setMatches(searchValue ? finalResult?.find(obj => obj._id === searchValue) : (finalResult?.find(obj => obj.status === 'live') || finalResult[0]))
                    setFiltersData(prevState => ({ ...prevState, match: searchValue ? finalResult?.find(obj => obj._id === searchValue) : (finalResult?.find(obj => obj.status === 'live') || finalResult[0]) }));
                    // !searchValue && setSearchQuery(history, { match: searchValue ? finalResult?.find(obj => obj._id === searchValue) : (finalResult?.find(obj => obj.status === 'live')?._id || finalResult[0]?._id) });
                }
                else {
                    setMatches();
                    setFiltersData(prevState => ({ ...prevState, match: null }));
                    // setSearchQuery(history, { match: null });
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                setMatches();
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Matches', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    const getLeagueSeasons = () => {
        setLoading(true);
        getLeaguesSeasonsList({
            params: { limit: pagination.limit, page: 1, status: leagueTabs[leagueTab].id },
            onSuccessCB: onSuccessLeagues, onErrorCB: onErrorLeagues,
        })
    }
    const onSuccessLeagues = (result, headers) => {
        setLoading(true);
        if (result) {
            pagination.page = parseInt(headers.current_page);
            pagination.total_count = parseInt(headers.total_count);
            setLeagues(prevState => ([...prevState, ...result]));
            // const searchValue = getSearchValue(history, 'league');
            // !searchValue && setSearchQuery(history, { league: result[0]?._id });
            // setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj?._id === searchValue) : result[0], round: null }));
            setLoading(true);
        }
        setLoading(false);
    }
    const onErrorLeagues = (error) => {
        setLoading(false);
        setFiltersData(prevState => ({ ...defaultFilters }));
    }
    const getLeagues = () => {
        setLoading(true);
        getLeaguesList({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                setLeagues([...result]);
                const searchValue = getSearchValue(history, 'league');
                !searchValue && setSearchQuery(history, { league: result[0]?._id });
                setFiltersData(prevState => ({ ...prevState, league: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null }));
                setLoading(false);

            }, onErrorCB: (error) => {
                setLoading(false);
                setFiltersData(prevState => ({ ...defaultFilters }));

            }
        })
    };

    const getSeasons = (leagueId) => {
        // setLoading(true);
        setLoadingSeasons(true);
        getSeasonsList({
            leagueId: leagueId,
            params: { limit: 5000, page: 1, status: "live" },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setSeasons([...result]);
                    const searchValue = getSearchValue(history, 'season');
                    !searchValue && setSearchQuery(history, { season: result[0]?._id });
                    if (!(filtersData.season?._id)) { setFiltersData(prevState => ({ ...prevState, season: searchValue ? result.find(obj => obj._id === searchValue) : result[0], round: null })); }
                    setSelectedSeason(searchValue ? result.find(obj => obj._id === searchValue) : result[0]);
                }
                else {
                    // setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));
                    setSeasons([]);
                    setRounds([]);
                }
                setLoadingSeasons(false);
            }, onErrorCB: (error) => {
                setLoadingSeasons(false);
                setFiltersData(prevState => ({ ...defaultFilters, league: prevState.league }));

            }
        })
    }


    const getSeasonKpiPositions = (seasonId) => {
        getSeasonKpiPositionList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
            }, onErrorCB: (error) => { }
        })
    }

    const getCountries = () => {
        getCountryCurrency({
            onSuccessCountry: (result,) => { }, onErrorCountry: (error) => { }
        })
    }

    const getRounds = (seasonId) => {
        setLoading(true);
        getRoundsList({
            seasonId: seasonId,
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result.length > 0) {
                    setRounds([...result]);
                    if (location.pathname.split('/')[2] === 'dashboard') {
                        setFiltersData(prevState => ({ ...prevState, match: null, round: null }));
                        const searchValue = getSearchValue(history, 'round');
                        !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                    }
                    else {
                        const searchValue = getSearchValue(history, 'round');
                        !searchValue && setSearchQuery(history, { round: result?.find(obj => obj._id === headers?.latest_round_id)?._id });
                        setFiltersData(prevState => ({ ...prevState, match: null, round: (searchValue ? result?.find(obj => obj._id === searchValue) : result?.find(obj => obj._id === headers?.latest_round_id)) }));
                    }
                }
                else {
                    setRounds([]);
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch Rounds', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const onSuccessCB = (response) => {
        if (response) {
            setUserInfo({ ...response });
        }
    }

    const onErrorCB = (error) => {
    }

    const handleTabChange = (e, newValue) => {
        historyPushWithSearch(`${path}/${MenuOptions[newValue].pathname}`, history, location.search);
        localStorage.setItem('leaderBoardFilters', '');
    }

    const handleRightClick = (event) => {
        event.preventDefault();
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const handleSeasonClick = (season) => {
        getSeasonDetail(season?._id);
        setAnchorEl(null);
        setMenuPosition(null);
        setSelectedSeason(season);
        let setObj = { season };
        if (season?.season_format === 'HEAD_TO_HEAD') getRounds(season?._id);
        else setObj['round'] = null;
        setFiltersData(prevState => ({ ...prevState, ...setObj }));
        setSearchQuery(history, { season: season?._id });
    }

    const [loadingSeasons, setLoadingSeasons] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleItemClick = (event, league) => {
        setAnchorEl(event.currentTarget);
        setFiltersData(prevState => ({ ...prevState, league }));
        setSearchQuery(history, { league: league?._id });
        // if (league) getSeasons(league._id);
    };



    const fetchMoreData = e => {
        getLeaguesSeasonsList({
            params: { limit: pagination.limit, page: pagination.page + 1, status: leagueTabs[leagueTab].id },
            onSuccessCB: onSuccessLeagues, onErrorCB: onErrorLeagues,
        });
    }
    const handleChangeTab = (event, newValue) => {
        setLeagueTab(newValue);
        setLeagues([]);
    };

    const handleGoBack = () => {
        history.push(`/leagues`);
    }

    return (
        <>
            <div className={classes.mainroot} id="leagues-root1" style={{ display: "flex", flexDirection: 'column', backgroundColor: "#F1F0FD", width: '100%', height: "calc(100vh - 60px)", overflowY: 'auto' }}>
                <div className={classes.heading}>
                    <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                    <h2>{filtersData?.league?.league_name}</h2>
                </div>
                <Tabs
                    id="filter-tabs"
                    value={MenuOptions.map((option) => option.pathname).indexOf(location.pathname.split('/')[3])}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="disabled tabs example"
                    // scrollButtons="on"
                    className={classes.tabsRoot}
                    classes={{ flexContainer: classes.tabsContainerStyles, indicator: classes.tabIndicatorProfile }}
                >
                    {
                        [...MenuOptions].map((obj, i) =>
                            <Tab className={classes.tabStyles} classes={{ selected: classes.selectedTab }} key={i} label={obj.name} />
                        )
                    }
                </Tabs>

                <Switch>
                    {/* <Route path={`${path}/dashboard`}>
                        <Dashboard filtersData={filtersData} rounds={rounds} userInfo={userInfo} />
                    </Route> */}
                    <Route path={`${path}/performance`}>
                        <DashboardNew filtersData={filtersData} rounds={rounds} userInfo={userInfo} setFiltersData={setFiltersData} divisions={divisions} matches={matches} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} teams={teams} />
                    </Route>
                    <Route path={`${path}/live-scores`}>
                        <LiveScores filtersData={filtersData} setFiltersData={setFiltersData} />
                    </Route>
                    <Route path={`${path}/points-table`} >
                        <PointsTables filtersData={filtersData} setFiltersData={setFiltersData} seasonDivisions={divisions} />
                    </Route>
                    {/* <Route path={`${path}/matches`} >
                        <AllMatches filtersData={filtersData} setFiltersData={setFiltersData} rounds={rounds} userInfo={userInfo} getRounds={getRounds} />
                    </Route> */}
                    <Route path={`${path}/stats`} >
                        <PerformanceStats type='stats' filtersData={filtersData} setFiltersData={setFiltersData} />
                    </Route>
                    <Route path={`${path}/trends`} >
                        <PerformanceStats type='trends' filtersData={filtersData} setFiltersData={setFiltersData} />
                    </Route>
                    <Route path={`${path}/leaderboard`} >
                        <Leaderboards filtersData={filtersData} setFiltersData={setFiltersData} seasonDivisions={divisions} seasonRegions={seasonRegions} seasonTeams={seasonTeams} />
                    </Route>
                    <Route path={`${path}/rewards`} >
                        <Rewards filtersData={filtersData} setFiltersData={setFiltersData} />
                    </Route>
                    <Route path={`${path}/season-rules`} >
                        <SeasonRules filtersData={filtersData} setFiltersData={setFiltersData} />
                    </Route>
                    <Redirect to={{ pathname: `${path}/performance`, search: location.search }} />
                </Switch>
                <ShareLiveScoreDialog />
                <CreatePost />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails,
    keycloak: state.keycloak,
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getLoggedInUserRole: options => dispatch(getLoggedInUserRole(options)),
        getSeasonsList: (options) => dispatch(getSeasonsList(options)),
        getSingleSeasonDetails: (options) => dispatch(getSingleSeasonDetails(options)),
        getLeaguesList: (options) => dispatch(getLeaguesList(options)),
        getLeaguesSeasonsList: (options) => dispatch(getLeaguesSeasonsList(options)),
        getRoundsList: (options) => dispatch(getRoundsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getTemplateTheme: (options) => dispatch(getTemplateTheme(options)),
        getCountryCurrency: (options) => dispatch(getCountryCurrency(options)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options)),
        getSeasonKpiPositionList: (options) => dispatch(getSeasonKpiPositionList(options)),
        getUserHierarchies: (options) => dispatch(getUserHierarchies(options)),
        getSeasonLiveMatchType: (options) => dispatch(getSeasonLiveMatchType(options)),
        getMySeasonTeams: (options) => dispatch(getMySeasonTeams(options)),
        getRegionsList: (options) => dispatch(getRegionsList(options)),
        getBreadcrumsData: (options) => dispatch(getBreadcrumsData(options)),
        getSeasonPlayersRoles: (options) => dispatch(getSeasonPlayersRoles(options)),
        getSeasonTeams: (options) => dispatch(getSeasonTeams(options))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaguesIndex));