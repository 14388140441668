import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getGlobalSearchResult, setArenaSearchQuery, setGlobalSearchQuery } from '../../ducks/search';
import { deleteMessagingDeviceToken, getInAppNotifications, readInAppNotificationsUnreadCount } from '../../ducks/notification';
import logo from '../../assets/images/leagues-logo-1.png';
import { setPresence } from '../../ducks/user';
import { disconnected, logout } from '../../ducks/client';
import { Avatar, Backdrop, CircularProgress, Divider, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText, Tab, Tabs, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ReactComponent as ReactNotification } from '../../assets/icons/notification.svg';
import { ReactComponent as ReactNotificationFilled } from '../../assets/icons/notification-2.svg';
import { ReactComponent as SwitchToAdmin } from "../../assets/icons/switch-admin-1.svg";
import { ReactComponent as AdminAccess } from "../../assets/icons/admin-access.svg";
import { ReactComponent as Settings } from "../../assets/icons/setting-1.svg";
import { ReactComponent as ReactUser } from "../../assets/icons/user.svg";
import { ReactComponent as ReactLock } from "../../assets/icons/lock.svg";
import { ReactComponent as ReactLogOut } from "../../assets/icons/logout.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification-1.svg";
import { orgRealm } from '../..';
import { getAvatarText, getEllipsisText, historyPushWithSearch } from '../../helpers';
import { openCreateArenaDialog } from '../../ducks/arena';
import { Chat, Notifications, NotificationsNone } from '@material-ui/icons';
import ChangePasswordDialog from './ChangePasswordDialog';
import { getBreadcrumsData } from '../../ducks/game';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
        // color: 'rgb(77 77 79 / 46%)',
        color: '#FFF',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    },
    title: {

        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        //     display: 'block',
        // },
    },
    logoRoot: {
        // backgroundColor: theme.palette.primary.main,
        // width: 75,
        // height: 60,
        // minWidth: 75,
        // maxWidth: 75,
        // maxHeight: 60,
        margin: '0px 2px 0px 10px',
        borderRadius: 4.6224,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            margin: '0px 5px 0px 0px'
        },
    },
    logo: {
        // logo styles
        width: '33px',
        height: '33px',
        maxWidth: '33px',
        maxHeight: '33px',
        border: '0.785714px solid #FFF',
        boxSizing: 'border-box',
        // filter: 'drop-shadow(0px 0.101036px 0.101036px rgba(0, 0, 0, 0.25))',
        padding: 3,
        borderRadius: 4.6224,
        // width: 'auto',
        // height: 'auto',
        // maxWidth: '85%',
        // maxHeight: '80%',
        // backgroundColor: '#FFF'
    },
    defautlLogo: {
        border: 'none',
        padding: 4,
        maxWidth: '33px',
        maxHeight: '33px',
        boxSizing: 'border-box',
    },
    search: {
        position: 'relative',
        borderRadius: 20,
        backgroundColor: '#F1F2F5',
        '&:hover': {
            backgroundColor: fade('#F1F2F5', 0.75),
        },
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: 'auto',
        // maxWidth: 250,
        maxWidth: 160,
        minWidth: 160,
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(3),
        //     width: 'auto',
        // },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#C4C4C4'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 20,
        fontSize: 12,
        // [theme.breakpoints.up('md')]: {
        //     width: '17ch',
        // },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            // maxWidth: 370,
            // minWidth: 220,
            // justifyContent: 'space-between',
            alignItems: 'center'
        },
    },
    sectionMobile: {
        color: 'rgb(77 77 79 / 46%)',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    // Additional
    appBarStyles: {
        // background: '#FFF',
        boxShadow: 'none',
        borderBottom: '1.5px solid #EEEEEE',
        color: '#262626'
    },
    gutters: {
        display: 'flex',
        justifyContent: 'space-between',
        // paddingLeft: 0,
        paddingLeft: 14,
        paddingRight: 14,
        // justifyContent: 'space-between',
        height: 60,
        minHeight: 60,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 5,
        },

    },
    navRoot: {
        // width: 480,
        // margin: 'auto',
        // display: 'flex',
        // justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    iconStyle: {
        margin: '0px 10px',
        padding: 0,
        '& svg': {
            width: 21,
            height: 21,
            // '& path': {
            //     fill: 'rgb(77 77 79 / 46%)',
            //     // stroke: 'rgb(77 77 79 / 46%)'
            // },
            // '& path[fill="white"]': {
            //     fill: 'white',
            // }
        },
    },
    selectedModule: {
        // '& svg': {
        //     '& path': {
        //         fill: '#4d4d4f',
        //         // stroke: '#4d4d4f'
        //     },
        //     '& path[fill="white"]': {
        //         fill: 'white',
        //     }
        // },
        '& .MuiListItemText-root': {
            '& span': {
                color: '#4d4d4f',
                fontWeight: 600,
                textTransform: 'capitalize',
            }
        },
    },
    drawerList: {
        width: 250,
    },
    listItemStyles: {
        '& span': {
            color: 'rgb(77 77 79 / 46%)',
            fontWeight: 600,
            textTransform: 'capitalize',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    },
    badgeStyle: {
        height: 13,
        width: 23,
        fontSize: 11,
        background: '#FF3B30 !important',
        color: '#FFF !important',
        fontWeight: 600,
        marginRight: '10px'
    },
    moreIconStyle: {
        padding: 0,
        margin: '0px 5px',
        color: 'rgb(77 77 79 / 46%)',
        // '& path': {
        //     fill: 'rgb(77 77 79 / 46%)',
        // },
        // '& path[fill="none"]': {
        //     fill: 'none',
        // },
        '&:hover': {
            background: 'none'
        }
    },
    avatarStyles: {
        width: 35,
        height: 35,
        fontSize: 16,
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: '#FFF',
        color: theme.palette.primary.main,
        border: '0.785714px solid #F0F2F5'
    },
    profilePopup: {
        width: '200px',
        top: '50px !important',
    },
    menuItemRoot: {
        height: 50,
        fontSize: 14,
        '&:hover': {
            fontWeight: 600
        }
    },
    profileIcons: {
        marginRight: 10,
        width: 22,
        height: 22,
        '& path': {
            fill: '#4D4D4F'
            // fill: theme.palette.primary.main,
            // color: theme.palette.primary.main,
            // stroke: theme.palette.primary.main,
        },
        '& path[fill="none"]': {
            // fill: 'none'
        }
    },
    menuIcons: {
        marginRight: 10,
        width: 22,
        height: 22,
    },
    switchToadminIcon: {
        marginRight: 10,
        width: 28,
        height: 28,
        [theme.breakpoints.down('md')]: {
            marginRight: 7,
            width: 24,
            height: 24,
        },
    },
    settingsPopup: {
        width: '200px',
        top: '50px !important',
        textTransform: 'capitalize'
    },
    settingsItemRoot: {
        height: 50,
        fontSize: 15,
        '&:hover': {
            fontWeight: 600
        },
        '& svg': {
            marginRight: 10,
            width: 22,
            height: 22,
            // '& path': {
            //     fill: theme.palette.primary.main,
            //     // color: theme.palette.primary.main,
            //     // stroke: theme.palette.primary.main,
            // },
            // '& path[fill="none"]': {
            //     fill: 'none',
            // }
        }
    },
    addIconStyle: {
        // marginRight: 20,
        height: 28,
        width: 28,
        [theme.breakpoints.down('sm')]: {
            marginRight: 5
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
        '& svg path': {
            fill: '#FFF'
        }
    },
    usernameStyles: {
        fontSize: 14,
        maxWidth: 70,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    tabsRoot: {
        // position: 'fixed',
        // top: 60,
        // left: 0,
        // backgroundColor: '#FFF',
        width: '100%',
        maxWidth: '100%',
        minHeight: 36,
        zIndex: 11,
        transition: 'top 0.2s ease-in-out',
        fontSize: 14
    },
    tabRoot: {
        minHeight: 36,
        textTransform: 'capitalize',
        // color: '#262626 !important',
        color: '#FFF',
        minWidth: 'auto',
        padding: '6px 4px',
        margin: '0 4px',
        opacity: 1,
        fontSize: 'inherit',
        '& svg path': {
            fill: '#FFF'
        }
        // [theme.breakpoints.up("sm")]: {
        //     minWidth: 125
        // }
    },
    flexContainerStyles: {
        justifyContent: 'center'
    },
    indicator: {
        backgroundColor: '#FFF'
    },
    scrollButtons: {
        width: 20,
        color: '#FFF',
        opacity: 1
    },
    selectedTab: {
        fontWeight: 600
    }
}));

const PrimarySearchAppBar = (props) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [query, setQuery] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);

    const [openProfile, setOpenProfile] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);

    const isAdmin = props.keycloak.decoded_token.realm_access.roles &&
        props.keycloak.decoded_token.realm_access.roles.length > 0 &&
        props.keycloak.decoded_token.realm_access.roles.includes('admin');

    const onGetInAppNotificationSuccess = (data, headers) => {
        if (headers.hasOwnProperty("unread_count")) {
            props.readInAppNotificationsUnreadCount(parseInt(headers.unread_count))
        }
    }
    const onGetInAppNotificationError = (error) => {
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to Fetch unread count.`, {
            variant: "error",
            preventDuplicate: true
        });
    };
    useEffect(() => {
        setQuery(props.searchQuery);
    }, [props.searchQuery]);

    useEffect(() => {
        props.getInAppNotifications({ params: { limit: 10, page: 1 }, onGetInAppNotificationSuccess, onGetInAppNotificationError });
    }, []);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        props.deleteMessagingDeviceToken('delete');
        setTimeout(() => {
            props.logout();
            if (props.client)
                props.client.disconnect();
            // props.disconnected();
            props.keycloak.logout({
                redirectUri: window.location.href
            });
        }, 100);
    }

    const handleProfileClick = () => {
        handleMenuClose();
        // setOpenProfile(true);
        // props.history.push(`/wall/profile/${props.user.username}`)
        props.history.push(`/profile/${props.user.username}`)
        props.getBreadcrumsData([]);
    }

    const handleCloseProfile = () => {
        setOpenProfile(false);
    }

    const handlePasswordChange = () => {
        handleMenuClose();
        setOpenChangePassword(true);
    }

    const handleChangePasswordDialog = () => {
        setOpenChangePassword(false);
    }
    const menuId = 'primary-search-account-menu';
    const renderProfileMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            classes={{ paper: classes.profilePopup }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* {presenceOpts.map((obj, i) => <MenuItem key={i} onClick={() => handleChangeStatus(obj)}>{obj.label}</MenuItem>)} */}
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleProfileClick()}>  <ReactUser className={classes.profileIcons} />Profile</MenuItem>
            {(props.orgDetails && props.orgDetails.auth_type === 'password') && <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handlePasswordChange()}> <ReactLock className={classes.profileIcons} /> Change Password</MenuItem>}
            {isAdmin && <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => { handleMobileMenuClose(); switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm)) }}> <AdminAccess className={classes.switchToadminIcon} />Admin Portal</MenuItem>}
            {/* <Divider component="li" /> */}
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleLogout()}> <ReactLogOut className={classes.profileIcons} /> Logout </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            classes={{ paper: classes.profilePopup }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleProfileClick()}>  <ReactUser className={classes.profileIcons} />Profile</MenuItem>
            {(props.orgDetails && props.orgDetails.auth_type === 'password') && <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handlePasswordChange()}> <ReactLock className={classes.profileIcons} />Change Password</MenuItem>}
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => { handleMobileMenuClose(); props.history.push("/wall/notifications"); props.getBreadcrumsData([]); }}> <ReactNotificationFilled className={classes.menuIcons} />Notification</MenuItem>
            {
                isAdmin &&
                <>
                    <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => { handleMobileMenuClose(); switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm)) }}> <AdminAccess className={classes.switchToadminIcon} />Admin Portal</MenuItem>
                    {/* <MenuItem classes={{ root: classes.menuItemRoot }} onClick={(e) => handleSettingsOpen(e)}> <Settings width="23px" height="23px" className={classes.menuIcons} />Admin Config</MenuItem> */}
                </>
            }
            <Divider />
            <MenuItem classes={{ root: classes.menuItemRoot }} onClick={() => handleLogout()}> <ReactLogOut className={classes.profileIcons} /> Logout</MenuItem>
        </Menu>
    );

    const handleChangeQuery = (e) => {
        let value = e.target.value;
        setQuery(value);
        if (value.length === 0) {
            if (location.pathname.startsWith("/wall") && value.length === 0) {
                props.setArenaSearchQuery("")
            }
            else {
                props.setGlobalSearchQuery('')
            }
        }
    }
    const handleKeyPress = e => {
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                if (query.length > 0) {
                    if (location.pathname.startsWith("/wall")) {
                        props.history.push({
                            pathname: "/wall/search",
                            search: `?q=${query}`
                        })
                        props.setArenaSearchQuery(query)
                    }
                    else if (location.pathname.startsWith("/chats") || location.pathname.startsWith("/search")) {
                        setLoading(true);
                        props.history.push({
                            pathname: '/search',
                            search: `?q=${query}`
                        })
                        props.getGlobalSearchResult({ query, onSearchSuccessCB, onSearchErrorCB });
                    }
                }
                else {
                    props.setGlobalSearchQuery('')
                }
            }
        }
    }
    const onSearchSuccessCB = (result) => {
        setLoading(false);
    }
    const onSearchErrorCB = (error) => {
        setLoading(false);
    }

    const switchApp = (url) => {
        window.location.href = url;
    }

    const getAdminRedirectUrl = (apiBaseUrl = '', orgCode = '', path = 'arena') => `${apiBaseUrl.replace('api', `${orgCode}.admin`)}/admin/${path}`;

    const handleOnSettingClick = (path) => {
        handleSettingsClose();
        switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm, path));
    }

    const isSettingsOpen = Boolean(settingsAnchorEl);

    const handleSettingsOpen = (event) => {
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const settingsId = 'admin-settings-menu';
    const renderSettingsMenu = (
        <Menu
            anchorEl={settingsAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            classes={{ paper: classes.settingsPopup }}
            id={settingsId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isSettingsOpen}
            onClose={handleSettingsClose}
        >
            <MenuItem disabled classes={{ root: classes.settingsItemRoot }} style={{ height: 40, borderBottom: '1px solid #909090' }}>Admin Configurations</MenuItem>
            {
                props.orgDetails.adminModules && props.orgDetails.adminModules.map((module, i) =>
                    <MenuItem key={i} classes={{ root: classes.settingsItemRoot }} onClick={() => handleOnSettingClick(module.path)}>{module.icon} {module.feature}</MenuItem>
                )
            }
        </Menu>
    );

    const handleCreatePost = () => {
        props.openCreateArenaDialog({ open: true, arena: {}, stepType: 'postType', attachment: [] })
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenDrawer(open);
    };

    const drawerList = (
        <div
            className={classes.drawerList}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {props.orgDetails?.theme?.view === 'PWC' ?
                    props.orgDetails.pwcModules.map((module, index) => (
                        <ListItem button key={index} onClick={() => props.history.push(`/${module.path}`)}
                            className={classNames({ [classes.selectedModule]: props.location.pathname.startsWith(`/${module.path}`) })}
                        >
                            <ListItemIcon><Icon className={classes.iconStyle}>{props.location.pathname.startsWith(`/${module.path}`) ? module.filledIcon : module.icon}</Icon></ListItemIcon>
                            <ListItemText className={classes.listItemStyles} primary={module.feature} />
                        </ListItem>
                    ))
                    :
                    props.orgDetails.modules.map((module, index) => (
                        <ListItem button key={index} onClick={() => { props.history.push(`/${module.path}`); props.getBreadcrumsData([]); }}
                            className={classNames({ [classes.selectedModule]: props.location.pathname.startsWith(`/${module.path}`) })}
                        >
                            <ListItemIcon><Icon className={classes.iconStyle}>{props.location.pathname.startsWith(`/${module.path}`) ? module.filledIcon : module.icon}</Icon></ListItemIcon>
                            <ListItemText className={classes.listItemStyles} primary={module.feature} />
                        </ListItem>
                    ))

                }
                <ListItem button onClick={() => { props.history.push(`/chatbot`); props.getBreadcrumsData([]); }}
                    className={classNames({ [classes.selectedModule]: props.location.pathname.startsWith(`/chatbot`) })}
                >
                    <ListItemIcon><Icon className={classes.iconStyle}><Chat /></Icon></ListItemIcon>
                    <ListItemText className={classes.listItemStyles} primary={'chatbot'} />
                </ListItem>
            </List >
        </div >
    );

    const handleModuleChange = (e, value) => {
        if (props.orgDetails?.theme?.view === 'PWC') {
            historyPushWithSearch(`/${props.orgDetails.pwcModules[value]?.path}`, props.history, props.location.search);

        }
        else if (value === 6) {
            props.history.push(`/chatbot`)
        }
        else {
            props.getBreadcrumsData([]);
            if (value !== 2) props.history.push(`/${props.orgDetails.modules[value]?.path}`) //historyPushWithSearch(`/${props.orgDetails.modules[value]?.path}`, props.history, props.location.search);
            else historyPushWithSearch(`/${props.orgDetails.modules[value]?.path}`, props.history, props.location.search);
        }

    }

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.appBarStyles}>
                <Toolbar classes={{ gutters: classes.gutters }}>
                    {/* <div style={{ display: 'flex', alignItems: 'center', width: '26%' }}> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography className={classes.title} variant="" noWrap>
                        Material-UI
          </Typography> */}
                        <div className={classes.logoRoot}>  {/* 'currentColor' */}
                            <img className={classes.logo} src={(props.orgDetails && props.orgDetails.logo_url) || logo} alt="logo" width="40" height="40" />
                        </div>
                    </div>
                    {/* <div style={{ flexGrow: 0.5 }} /> */}
                    {/* <div style={{ width: '45%' }}> */}
                    <div style={{ flexGrow: 1, margin: '0px 10px 0px 0px' }} >
                        <div className={classes.navRoot}>
                            <Tabs
                                id="top-menu-tabs"
                                value={props.orgDetails?.theme?.view === 'PWC' ? props.orgDetails.pwcModules?.findIndex(module => props.location.pathname.includes(module.path)) : props.orgDetails.modules?.findIndex(module => props.location.pathname.includes(module.path))}
                                onChange={(e, value) => handleModuleChange(e, value)}
                                indicatorColor="primary"
                                // textColor="primary"
                                variant="scrollable"
                                scrollButtons="on"
                                aria-label="scrollable auto tabs example"
                                className={classes.tabsRoot}
                                classes={{ indicator: classes.indicator, scrollButtons: classes.scrollButtons }}
                            >
                                {
                                    props.orgDetails?.theme?.view === 'PWC' ?
                                        props.orgDetails.pwcModules?.map((item, i) =>
                                            <Tab className={classes.tabRoot} key={i} classes={{ selected: classes.selectedTab }} label={item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())} />
                                        )
                                        :
                                        props.orgDetails.modules?.map((item, i) =>
                                            <Tab className={classes.tabRoot} key={i} classes={{ selected: classes.selectedTab }} label={item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())} />
                                        )




                                }
                                <Tab className={classes.tabRoot} classes={{ selected: classes.selectedTab }} label='Chat' />
                            </Tabs>
                            {/* {props.orgDetails.modules.map((item, i) =>
                                <div style={{ textAlign: 'center' }}>
                                    <IconButton disableTouchRipple
                                        onClick={() => props.history.push(`/${item.path}`)}
                                        title={item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())} key={i} aria-label={item.feature}
                                        color="inherit" className={classNames(classes.iconStyle, { [classes.selectedModule]: props.location.pathname.startsWith(`/${item.path}`) })}
                                    >
                                        <Badge badgeContent={0} color="secondary">
                                            {props.location.pathname.startsWith(`/${item.path}`) ? item.filledIcon : item.icon}
                                        </Badge>
                                    </IconButton>
                                    <p style={{ margin: 0, fontSize: 10, textAlign: 'center', cursor: 'pointer' }} onClick={() => props.history.push(`/${item.path}`)}>{item.feature && item.feature.replace(/\b(\w)/g, s => s.toUpperCase())}</p>
                                </div>
                            )}
                        */}
                        </div>
                    </div>
                    {/* <div style={{ flexGrow: 1.5 }} /> */}
                    {/* <div style={{ width: '35%', display: 'flex', justifyContent: 'flex-end' }}> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            (location.pathname.startsWith("/wall") || location.pathname.startsWith("/chats") || location.pathname.startsWith("/search")) &&
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={query}
                                    onChange={handleChangeQuery}
                                    onKeyUp={handleKeyPress}
                                />
                            </div>
                            // : <div style={{ flexGrow: 1 }} />
                        }
                        <div className={classes.sectionDesktop} style={{ width: isAdmin ? '100%' : '' }}>
                            <Tabs
                                id="top-menu-tabs"
                                // value={props.orgDetails.modules?.findIndex(module => props.location.pathname.includes(module.path))}
                                // onChange={(e, value) => props.history.push(`/${props.orgDetails.modules[value]?.path}`)}
                                indicatorColor="primary"
                                // textColor="primary"
                                variant="scrollable"
                                scrollButtons="off"
                                aria-label="scrollable auto tabs example"
                                className={classes.tabsRoot}
                                classes={{ indicator: classes.indicator, scrollButtons: classes.scrollButtons }}
                            // style={{ fontSize: 14 }}
                            >
                                {props.location.pathname.startsWith('/wall') && <Tab onClick={handleCreatePost} className={classes.tabRoot} key={0} classes={{ selected: classes.selectedTab }} label={"Create post"} />}
                                {/* {isAdmin && <Tab onClick={() => switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm))} className={classes.tabRoot} key={2} classes={{ selected: classes.selectedTab }} label={"Admin portal"} />} */}
                                <Tooltip title="Notifications">
                                    <Tab onClick={() => { props.history.push("/wall/notifications"); props.getBreadcrumsData([]); }} className={classes.tabRoot} key={1} classes={{ selected: classes.selectedTab }} label={""} icon={props.location.pathname.startsWith('/wall/notifications') ? <Notifications width={14} height={26} style={{ marginBottom: 0, marginRight: '5px' }} /> : <Badge classes={{ badge: classes.badgeStyle }} max={9} badgeContent={props.unreadCount >= 1 ? props.unreadCount : 0}><NotificationsNone width={14} height={26} style={{ marginBottom: 0 }} /></Badge>} />
                                </Tooltip >
                            </Tabs >
                            {/* <div>
                                < IconButton
                                    aria-label="Create post"
                                    aria-haspopup="true"
                                    onClick={handleCreatePost}
                                    color="inherit"
                                    disableTouchRipple
                                    className={classNames(classes.moreIconStyle, classes.addIconStyle)}
                                    style={{ visibility: props.location.pathname.startsWith('/wall') ? 'visible' : 'hidden', marginLeft: 15 }}
                                >
                                    <AddIcon />
                                </IconButton>
                                <p style={{ visibility: props.location.pathname.startsWith('/wall') ? 'visible' : 'hidden', margin: 0, fontSize: 10, textAlign: 'center', cursor: 'pointer' }} onClick={handleCreatePost}>Create post</p>
                            </div>
                            <div>
                                <IconButton disableTouchRipple className={classes.moreIconStyle} aria-label="show notifications" color="inherit"
                                    style={{ marginLeft: 22, marginTop: 2 }}
                                    onClick={() => {
                                        props.history.push("/wall/notifications");
                                    }}
                                >
                                    <Badge classes={{ badge: classes.badgeStyle }} max={9} badgeContent={props.unreadCount >= 1 ? props.unreadCount : 0}>
                                        <NotificationIcon width={14} height={26} />
                                    </Badge>
                                </IconButton>
                                <p style={{ margin: 0, fontSize: 10, textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                                    props.history.push("/wall/notifications");
                                }}>Notifications</p>
                            </div>
                            {
                                isAdmin &&
                                <>
                                    <div>
                                        <IconButton style={{ marginLeft: 38, marginTop: 4 }} disableTouchRipple className={classes.moreIconStyle}
                                            onClick={() => switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm))}
                                        >
                                            <SwitchToAdmin width="23px" height="23px" />
                                        </IconButton>
                                        <p style={{ margin: 0, fontSize: 10, textAlign: 'center', cursor: 'pointer' }} onClick={() => switchApp(getAdminRedirectUrl(process.env.REACT_APP_BASE_URL, orgRealm))}>Switch to Admin portal</p>
                                    </div>
                                </>
                            } */}
                            < div style={{ display: 'flex' }}>
                                <IconButton
                                    disableTouchRipple className={classes.moreIconStyle}
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                    title={props.user.name}
                                >
                                    <Avatar
                                        className={classes.avatarStyles}
                                        src={props.user.photo_url || props.user.photoUrl}
                                    >
                                        {getAvatarText(props.user.name)}
                                    </Avatar>
                                </IconButton>
                            </div >
                        </div >
                    </div >
                    <div className={classes.sectionMobile}>
                        {
                            props.location.pathname.startsWith('/wall') &&
                            <Tooltip title="Create Post">
                                <IconButton
                                    // style={{ display: 'none' }}
                                    aria-label="Create post"
                                    aria-haspopup="true"
                                    onClick={handleCreatePost}
                                    color="inherit"
                                    disableTouchRipple
                                    className={classNames(classes.moreIconStyle, classes.addIconStyle)}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            style={{ padding: 8, color: '#FFF' }}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar >
            </AppBar >
            {renderMobileMenu}
            {renderProfileMenu}
            {renderSettingsMenu}
            <Drawer anchor={'left'} open={openDrawer} onClose={toggleDrawer(false)}>
                {drawerList}
            </Drawer>
            {openChangePassword && <ChangePasswordDialog open={openChangePassword} handleChangePasswordDialog={handleChangePasswordDialog} />}
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    client: window.client,
    user: state.user,
    keycloak: state.keycloak,
    searchQuery: state.search.searchQuery,
    unreadCount: state.notification.inAppNotificationUnreadCount,
    orgDetails: state.common.orgDetails,
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        setArenaSearchQuery: (options) => dispatch(setArenaSearchQuery(options)),
        getInAppNotifications: (options) => dispatch(getInAppNotifications(options)),
        setPresence: (presence) => dispatch(setPresence(presence)),
        getGlobalSearchResult: (options) => dispatch(getGlobalSearchResult(options)),
        setGlobalSearchQuery: (options) => dispatch(setGlobalSearchQuery(options)),
        deleteMessagingDeviceToken: (options) => dispatch(deleteMessagingDeviceToken(options)),
        readInAppNotificationsUnreadCount: (options) => dispatch(readInAppNotificationsUnreadCount(options)),
        logout: (options) => dispatch(logout(options)),
        disconnected: (options) => dispatch(disconnected(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options)),
        getBreadcrumsData: (options) => dispatch(getBreadcrumsData(options)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PrimarySearchAppBar);