import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import Axios from "axios";
import './ChatBot.css';
import { CHAT_GPT_INTEGRATION } from "../../config";
import { markdown } from "./MarkDown";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { createChat, createNewChatMessages, getChatMessagesList, rateMessages } from "../../ducks/chatgpt";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeOfChatRoles } from ".";
import ReactStars from "react-rating-stars-component";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    root1: {
        // width: '100% !important',
        // height: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '0px 15%',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        '& .send_btn': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .heading': {
            color: '#5C2A7D',
            textAlign: 'center',
            fontSize: '23px',
            margin: '5px 0px'
        }
    },
    searchContainer: {
        display: 'flex',
        borderRadius: '22px', /* Adjust as needed */
        boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)', /* Creates the shadow effect */
        overflow: 'hidden',
        border: '1px solid #d4d4d4',
        width: '100%',

    },
    searchInput: {
        border: 'none',
        outline: 'none',
        padding: '10px 20px',
        fontSize: 16, /* Adjust as needed */
    },
    searchButton: {
        background: '#e8e8e8',
        border: 'none',
        cursor: 'pointer',
        padding: '4px 6px',
        margin: 8,
        borderRadius: 10,
    },
    searchImg: {
        width: 20, /* Adjust as needed */
        height: 20, /* Adjust as needed */
    },
    query: {
        border: '1px solid #d4d4d4',
        borderRadius: 15,
        padding: '10px 15px !important',
        color: 'dimgrey',
        cursor: 'pointer',
        fontSize: 11,
        fontWeight: 'bold',
        minHeight: 33
    },
    autocompleteRoot: {
        borderRadius: '6px',
        padding: '0px !important',
        width: 130
    },
    lengthView: {
        display: 'block',
        color: ' rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '1px',
        textAlign: 'right',
        fontWeight: '400',
        lineHeight: '1.66',
        letterSpacing: '0.03333em'
    },
}));



const querysList = [
    {
        id: 1,
        question: 'What are the reasons for rejection of claim?'
    },
    {
        id: 2,
        question: 'How to cross sell a Insurance product?'
    },
    {
        id: 3,
        question: 'In how may days a customer claim will be settled?'
    },
    {
        id: 4,
        question: 'What are the document needed to purchase Term insurance policies?'
    }
]




const ChatList = ({ history, createChat, rateMessages, recentChatId, getChatMessagesList, createNewChatMessages, typeOfRole, setTypeOfRole, newChat, setNewChat, setRecentChatId }) => {
    const classes = useStyles();

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const myMessage = useRef(null);
    const [rateState, setRateState] = useState(null)

    useEffect(() => {
        myMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: "nearest" });
    }, [messages]);



    useEffect(() => {
        if (recentChatId && !newChat) {
            getRecentChatMessagesList(recentChatId);
        }
    }, [recentChatId, typeOfRole?.id])


    useEffect(() => {
        if (newChat) {
            OnCreateChat();
            setMessages([]);
        }
    }, [newChat]);


    // ---------chat creation ---------
    const OnCreateChat = () => {
        setLoading(true);
        createChat({
            requestObj: {},
            onSuccessCB: (result) => {
                setRecentChatId(result.id);
                setNewChat(null);
                history.push(`/chatbot/${result.id}`)
            }, onErrorCB: (err) => {

            }
        })
    }
    // ---------chat creation ---------


    // ----chat messageLists-----------
    const getRecentChatMessagesList = (chatId) => {
        setLoading(true);
        getChatMessagesList({
            chatId: chatId,
            params: { limit: 5000, page: 1, type: typeOfRole?.id },
            onSuccessCB: (result) => {
                setMessages(result);
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
            }
        })
    }
    // ----chat messageLists-----------

    const onQueryClick = async (query) => {
        loadingMessage(query);
        createNewChatMessages({
            chatId: recentChatId,
            requestObj: { message: query, type: typeOfRole?.id },
            onSuccessCB: (result) => {
                getRecentChatMessagesList(recentChatId);
            }, onErrorCB: (error) => {
                setLoading(false);
            }
        })
    }

    //"Thinking..."
    const ThinkingLoader = () => {
        const classes = useStyles();

        return (
            <div className="loader"></div>
        )
    }


    const loadingMessage = (input) => {
        const dubMessages = [
            { _id: "123434", rating: 0, message: input, role: 'USER', title: "", created_at: '' },
            { _id: "1234345", rating: 0, role: 'AGENT', title: "", message: 'Thinking...', loading: true, created_at: '' }
        ]
        setMessages([...messages, ...dubMessages]);
        setInput('');
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        setInput('');
        if (input.trim() !== '') {
            if (recentChatId) {
                loadingMessage(input);
                createNewChatMessages({
                    chatId: recentChatId,
                    requestObj: { message: input, type: typeOfRole?.id },
                    onSuccessCB: (result) => {
                        getRecentChatMessagesList(recentChatId);
                    }, onErrorCB: (error) => {
                        setLoading(false);
                    }
                })
            }
        }

    };


    //rate a message
    const messageRating = (msg, value) => {
        let copy_messages = JSON.parse(JSON.stringify(messages))
        const msgIndex = copy_messages.findIndex(ms => ms._id === msg._id)
        if (value) {
            copy_messages[msgIndex].rating = value;
            setMessages([...copy_messages]);
            updateMessages(copy_messages[msgIndex]._id, value)
        }
    }
    const updateMessages = (msgId, value) => {
        rateMessages({ msgId, requestObj: { rating: value } });
    }



    return (
        <div id="ChatBot-data" className={classes.root}>
            <div style={{ width: 120, position: 'absolute', top: 85, }}>
                <Autocomplete
                    id='role'
                    autoComplete
                    options={typeOfChatRoles}
                    disableClearable
                    size="small"
                    getOptionLabel={(option) => option.name}
                    value={typeOfRole}
                    renderInput={(params) => <TextField {...params} style={{ fontSize: '10px !important', padding: '0px !important' }} label="Select Role" variant="outlined" />}
                    onChange={(e, val) => setTypeOfRole(val)}
                    classes={classes.autocompleteRoot}
                    style={{ padding: '0px !important', }}
                />
            </div>
            <div className={classes.root1}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div className="chat-interface">
                        {/* <h2 className="heading text-center mb-4">Ask your AI Buddy </h2> */}
                        <div className="messages" ref={myMessage}>
                            <div style={{ minHeight: 300 }}>
                                {messages.map((msg, msgIndex) =>
                                    <>
                                        <div key={`message_${msg._id}`} className="message">
                                            {
                                                msg.role === 'AGENT' ?
                                                    <>
                                                        <div className="messageAnswer" style={msg?.loading ? { fontSize: 13, lineHeight: '8px' } : {}}>
                                                            {msg.loading ?
                                                                <ThinkingLoader />
                                                                :
                                                                <div>
                                                                    <span dangerouslySetInnerHTML={{ __html: markdown(msg.message) }} />
                                                                    <div style={{ float: 'right', marginTop: '-5px' }}>
                                                                        <ReactStars
                                                                            count={5}
                                                                            classNames={classes.rating}
                                                                            value={msg?.rating || 0}
                                                                            onChange={(newRating) => messageRating(msg, newRating)}
                                                                            size={20}
                                                                            style={{ float: 'right', marginTop: '-8px' }}
                                                                            activeColor="#ffd700"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="query">
                                                        {msg.message}
                                                    </div>
                                            }
                                        </div>
                                    </>
                                )}
                            </div>
                            {(messages?.length === 0) &&
                                // <div style={{ position: 'absolute', bottom: 70, left: 0, padding: '0px 15px' }}>
                                <div style={{ padding: 0 }}>
                                    <h3 style={{ textAlign: 'center' }}>How can I help you today? </h3>
                                    <Grid container spacing={1}>
                                        {querysList.map((el, i) =>
                                            <Grid key={i} item xs={6}>
                                                <div className={classes.query} onClick={() => onQueryClick(el?.question)} >
                                                    {el.question}
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ width: '100%' }} >
                        <form onSubmit={sendMessage} style={{ display: 'block' }}>
                            {/* <input
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        type="text"
                        placeholder="Type your message..."
                    /> */}
                            {/* <button className="send_btn" type="submit">Send</button> */}
                            <div className={classes.searchContainer}>
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    className={classes.searchInput}
                                    placeholder="Ask AI Buddy..."
                                    inputProps={{ maxLength: 2000 }}
                                    max={2000}
                                    maxLength={2000}
                                />
                                <button className={classes.searchButton}>
                                    <ArrowUpwardIcon className={classes.searchImg} src="search-icon.png" alt="Search" />
                                </button>
                            </div>
                            <p className={classes.lengthView}> {`${input.length}/2000`} </p>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
};


const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getChatMessagesList: value => dispatch(getChatMessagesList(value)),
        createNewChatMessages: value => dispatch(createNewChatMessages(value)),
        createChat: value => dispatch(createChat(value)),
        rateMessages: value => dispatch(rateMessages(value)),
    };
};
export default withRouter(connect(null, mapDispatchToProps)(ChatList));