import { all } from "redux-saga/effects";

import chats from './chats'
import contacts from "./contacts";
import userDetails from "./userDetails";
import s3 from "./s3";
import teams from "./teams";
import search from "./search";
import surveys from "./surveys";
import notifications from "./notifications"
import messages from "./messages";
import arena from "./arena";
import favourites from "./favourites";
import meetings from "./meetings";
import pms from './pms';
import game from './game';
import themes from "./themes";
import nudge from "./nudge";
import chatgpt from "./chatgpt";

function* runLoop(token) {
  let restarts = 0;

  while (true) {
    try {
      yield all([
        chats(token),
        contacts(),
        userDetails(),
        s3(),
        teams(),
        search(),
        surveys(),
        notifications(),
        messages(),
        arena(),
        favourites(),
        meetings(),
        pms(),
        game(),
        themes(),
        nudge(),
        chatgpt()
      ]);
    } catch (e) {
      console.error("Caught error in saga, restarting:");
      console.error(e);
      restarts++;
      if (restarts > 10) {
        throw new Error("Already restarted 10 times");
      }
    }
  }
}

function* apiServiceSaga(token) {
  yield all([runLoop(token)]);
}

export default apiServiceSaga;
