import { combineReducers } from "redux";

import bookmarks from '../ducks/bookmarks';
import client from '../ducks/client';
import files from '../ducks/files';
import forms from '../ducks/forms';
import local from '../ducks/local';
import rooms from '../ducks/rooms';
import messages from '../ducks/messages';
import notification from '../ducks/notification';
import presence from '../ducks/presence';
import settings from '../ducks/settings';
import toast from '../ducks/toast';
import user from '../ducks/user';
import contacts from '../ducks/contacts';
import keycloak from '../ducks/keycloak';
import chats from '../ducks/chats';
import teams from '../ducks/teams';
import common from '../ducks/common';
import imageViewer from '../ducks/imageViewer';
import videoPlayer from '../ducks/videoPlayer';
import search from '../ducks/search';
import surveys from '../ducks/surveys';
import sidebar from '../ducks/sidebar';
import arena from '../ducks/arena';
import favourites from '../ducks/favourites';
import loading from '../ducks/loading';
import snackbar from '../ducks/snackbar';
import meetings from '../ducks/meetings';
import pms from '../ducks/pms';
import network from '../ducks/network';
import game from '../ducks/game';

export default combineReducers({
  bookmarks,
  client,
  files,
  forms,
  local,
  rooms,
  messages,
  notification,
  presence,
  settings,
  toast,
  user,
  contacts,
  keycloak,
  chats,
  teams,
  common,
  imageViewer,
  videoPlayer,
  search,
  surveys,
  sidebar,
  arena,
  favourites,
  loading,
  meetings,
  pms,
  game,
  snackbar,
  network,
});
